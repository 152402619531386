<template>
    <div class="rightLine" ref="rightLine">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'rightLine',
    props: {
        researchOutput: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        researchOutput: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr) {
            this.myChart = echarts.init(this.$refs['rightLine']);
            const xdata = []
            const ydata1 = []
            const ydata2 = []
            const ydata3 = []
            const ydata4 = []
            arr.map(data => {
                xdata.push(data.institutionName)
                ydata1.push(data.verticalProjectCnt)
                ydata2.push(data.intlPropApplyCnt)
                ydata3.push(data.intlPropApplyAuth)
                ydata4.push(data.servCompanyCnt)
            })
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(0, 255, 233,0)'
                                }, {
                                    offset: 0.5,
                                    color: 'rgba(255, 255, 255,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(0, 255, 233,0)'
                                }],
                                global: false
                            }
                        },
                    },
                },
                grid: {
                    top: '15%',
                    left: '10%',
                    right: '5%',
                    bottom: '15%',
                    // containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: false,
                        color: '#A582EA'
                    },

                    axisLabel: {
                        color: '#A582EA',
                        width: 100,
                        interval: 0,
                        rotate: 15,
                    },
                    splitLine: {
                        show: false
                    },
                    boundaryGap: false,
                    data: xdata

                }],

                yAxis: [{
                    type: 'value',
                    min: 0,
                    // max: 140,
                    splitNumber: 4,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#00BFF3',
                            opacity: 0.23
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        margin: 20,
                        textStyle: {
                            color: '#fff',

                        },
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [
                    {
                        name: '纵向科研项目数',
                        type: 'line',
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: "#00ff00",
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#00ff00',
                            }
                        },
                        itemStyle: {
                            color: "#fff",
                            borderColor: "#00ff00",
                            borderWidth: 2,
                        },
                        data: ydata1
                    },
                    {
                        name: '一类知识产权申请数',
                        type: 'line',
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: "#7b68ee",
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#7b68ee',
                            }
                        },
                        itemStyle: {
                            color: "#fff",
                            borderColor: "#7b68ee",
                            borderWidth: 2,
                        },
                        data: ydata2//data.values
                    },
                    {
                        name: '一类知识产权授权数',
                        type: 'line',
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: "#ff9999",
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#ff9999',
                            }
                        },
                        itemStyle: {
                            color: "#fff",
                            borderColor: "#ff9999",
                            borderWidth: 2,
                        },
                        data: ydata3
                    },
                    {
                        name: '横向服务企业数',
                        type: 'line',
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: "#ffe600",
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#ffe600',
                            }
                        },
                        itemStyle: {
                            color: "#fff",
                            borderColor: "#ffe600",
                            borderWidth: 2,
                        },
                        data: ydata4
                    },
                ]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.rightLine {
    width: 100%;
    height: 100%;
}
</style>
      