<template>
    <div style="width:100%;height: 100%;" ref="leftcenter">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: "leftCenter",
    props: {
        topTotalScore: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        topTotalScore: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.init(val)
                    }
                })
            }
        }
    },
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        init(arr) {
            const splicedata = arr
            this.myChart = echarts.init(this.$refs['leftcenter']);
            const xdata = []
            const ydata = []
            splicedata.map(data => {
                xdata.push(data.projectCenterName)
                ydata.push(data.totalScore)
            })
            const data = ydata;
            const className = xdata;
            const colorList = ['#39B3FF', '#47E0E0', '#7891D9'];
            const defaultData = [100, 100, 100];
            const option = {
                grid: {
                    left: '5%',
                    right: '5%',
                    bottom: '5%',
                    top: '10%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function (params) {
                        return params[0].name + '<br/>' +
                            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                            // params[0].seriesName + ' : ' + Number((params[0].value.toFixed(4) / 10000).toFixed(2)).toLocaleString() + ' <br/>'
                            params[0].seriesName + ' : ' + params[0].value
                    }
                },
                xAxis: {
                    show: false,
                    type: 'value'
                },
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        },
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    data: className
                }, {
                    type: 'category',
                    inverse: true,
                    axisTick: 'none',
                    axisLine: 'none',
                    show: true,
                    axisLabel: {
                        textStyle: {
                            color: '#ffffff',
                            fontSize: '12'
                        },
                    },
                    data: data
                }],
                series: [{
                    name: '支出',
                    type: 'bar',
                    zlevel: 1,
                    itemStyle: {
                        normal: {
                            barBorderRadius: 0,
                            color: (params) => {
                                return colorList[params.dataIndex]
                            }
                        },
                    },
                    barWidth: 20,
                    data: data
                },
                {
                    name: '背景',
                    type: 'bar',
                    barWidth: 20,
                    barGap: '-100%',
                    data: defaultData,
                    itemStyle: {
                        normal: {
                            color: '#1B375E',
                            barBorderRadius: 0,
                        }
                    },
                },
                ]
            };
            this.myChart.setOption(option);
        },
    }

}
</script>
