import { render, staticRenderFns } from "./indexcard.vue?vue&type=template&id=3fc94ebb&scoped=true&"
import script from "./indexcard.vue?vue&type=script&lang=js&"
export * from "./indexcard.vue?vue&type=script&lang=js&"
import style0 from "./indexcard.vue?vue&type=style&index=0&id=3fc94ebb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc94ebb",
  null
  
)

export default component.exports