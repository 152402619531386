<template>
    <div class="dv-border-box-13" :ref="ref">
        <svg class="dv-border-svg-container" :width="width" :height="height">
            <path :fill="backgroundColor" :stroke="mergedColor[0]" :d="`
          M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
          L ${width - 20} 10 L ${width - 5} 25
          L ${width - 5} ${height - 5} L 20 ${height - 5}
          L 5 ${height - 20} L 5 20
        `" />

            <path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5"
                :stroke="mergedColor[0]" :d="`M 16 9 L 61 9`" />

            <path fill="transparent" :stroke="mergedColor[1]" :d="`M 5 20 L 5 10 L 12 3  L 60 3 L 68 10`" />

            <path fill="transparent" :stroke="mergedColor[1]"
                :d="`M ${width - 5} ${height - 30} L ${width - 5} ${height - 5} L ${width - 30} ${height - 5}`" />
        </svg>

        <div class="border-box-content">
            <div class="lr_titles">
                <div class="item_title" v-if="title !== ''">
                    <div class="zuo"></div>
                    <span class="title-inner"> &nbsp;&nbsp;{{ title }}&nbsp;&nbsp; </span>
                    <div class="you"></div>
                </div>
                <div :class="title !== '' ? 'item_title_content' : 'item_title_content_def'">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
export default {
    props: {
        title: {
            type: String,
            default: () => "",
        },
        color: {
            type: Array,
            default: () => ([])
        },
        backgroundColor: {
            type: String,
            default: 'transparent'
        }
    },
    data() {
        return {
            ref: 'border-box-13',

            defaultColor: ['#6586ec', '#2cf7fe'],
            dom: '',

            width: 0,
            height: 0,

            debounceInitWHFun: '',

            domObserver: '',
            mergedColor: []
        }
    },
    methods: {
        async autoResizeMixinInit() {
            const { initWH, getDebounceInitWHFun, bindDomResizeCallback, afterAutoResizeMixinInit } = this

            await initWH(false)

            getDebounceInitWHFun()

            bindDomResizeCallback()

            if (typeof afterAutoResizeMixinInit === 'function') afterAutoResizeMixinInit()
        },
        initWH(resize = true) {
            const { $nextTick, $refs, ref, onResize } = this

            return new Promise(resolve => {
                $nextTick(_ => {
                    const dom = this.dom = $refs[ref]

                    this.width = dom ? dom.clientWidth : 0
                    this.height = dom ? dom.clientHeight : 0

                    if (!dom) {
                        console.warn('DataV: Failed to get dom node, component rendering may be abnormal!')
                    } else if (!this.width || !this.height) {
                        console.warn('DataV: Component width or height is 0px, rendering abnormality may occur!')
                    }

                    if (typeof onResize === 'function' && resize) onResize()

                    resolve()
                })
            })
        },
        getDebounceInitWHFun() {
            const { initWH } = this

            this.debounceInitWHFun = this.debounce(100, initWH)
        },
        bindDomResizeCallback() {
            const { dom, debounceInitWHFun } = this

            this.domObserver = this.observerDomResize(dom, debounceInitWHFun)

            window.addEventListener('resize', debounceInitWHFun)
        },
        unbindDomResizeCallback() {
            let { domObserver, debounceInitWHFun } = this

            if (!domObserver) return

            domObserver.disconnect()
            domObserver.takeRecords()
            domObserver = null

            window.removeEventListener('resize', debounceInitWHFun)
        },
        mergeColor() {
            const { color, defaultColor } = this

            this.mergedColor = this.deepMerge(this.deepClone(defaultColor, true), color || [])
        },
        observerDomResize(dom, callback) {
            const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver

            const observer = new MutationObserver(callback)

            observer.observe(dom, { attributes: true, attributeFilter: ['style'], attributeOldValue: true })

            return observer
        },
        debounce(delay, callback) {
            let lastTime

            return function () {
                clearTimeout(lastTime)

                const [that, args] = [this, arguments]

                lastTime = setTimeout(() => {
                    callback.apply(that, args)
                }, delay)
            }
        },
        deepMerge(target, merged) {
            // 判断传入的参数是否为对象
            if (typeof target !== 'object' || typeof merged !== 'object') {
                return target;
            }

            // 遍历被合并对象的键
            for (var key in merged) {
                // 如果目标对象和被合并对象都有相同的键且值都是对象，则递归调用deepMerge函数进行深度合并
                if (target.hasOwnProperty(key) && typeof target[key] === 'object' && typeof merged[key] === 'object') {
                    target[key] = this.deepMerge(target[key], merged[key]);
                }
                // 如果被合并对象的值是对象，则执行深拷贝后赋值给目标对象
                else if (typeof merged[key] === 'object') {
                    target[key] = this.deepClone(merged[key]);
                }
                // 否则直接将被合并对象的值赋给目标对象的相应键
                else {
                    target[key] = merged[key];
                }
            }

            return target;
        },

        // 深拷贝函数
        deepClone(obj) {
            if (obj === null || typeof obj !== 'object') {
                return obj;
            }

            var clone = Array.isArray(obj) ? [] : {};

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    clone[key] = this.deepClone(obj[key]);
                }
            }

            return clone;
        }

    },
    mounted() {
        const { mergeColor } = this

        mergeColor()

        const { autoResizeMixinInit } = this

        autoResizeMixinInit()
    },
    beforeDestroy() {
        const { unbindDomResizeCallback } = this

        unbindDomResizeCallback()
    }
};
</script>

<style lang='scss' scoped>
$item-title-height: 38px;
$item_title_content-height: calc(100% - 38px);

.lr_titles {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .border-box-content {
        box-sizing: border-box;
        padding: 6px 16px 0px;
    }

    .item_title {
        height: $item-title-height;
        line-height: $item-title-height;
        width: 100%;
        color: #31abe3;
        text-align: center;
        // background: linear-gradient(to right, transparent, #0f0756, transparent);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .zuo,
        .you {
            width: 58px;
            height: 14px;
            background-image: url(../../../assets/images/img/titles/zuo.png);
        }

        .you {
            transform: rotate(180deg);
        }

        .title-inner {
            font-weight: 900;
            letter-spacing: 2px;
            background: linear-gradient(92deg,
                    #0072ff 0%,
                    #00eaff 48.8525390625%,
                    #01aaff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .item_title_content {
        height: $item_title_content-height;
    }

    .item_title_content_def {
        width: 100%;
        height: 100%;
    }
}

.dv-border-box-13 {
    position: relative;
    width: 100%;
    height: 100%;

    .dv-border-svg-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }

    .border-box-content {
        position: relative;
        width: 100%;
        height: 100%;
    }
}
</style>