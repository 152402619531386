<template>
    <div class="data">
        <div class="data-title">
            <div class="title-left fl"></div>
            <div class="title-center fl">企业维度指标统计</div>
            <div class="title-right fr"></div>
        </div>
        <div class="data-content">
            <div class="con-left fl">
                <div class="left-top">
                    <div class="info">
                        <div class="info-title">实时统计</div>
                        <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                        <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                        <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                        <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                        <div class="info-main">
                            <div class="info-1">
                                <div class="info-img fl">
                                    <img src="../../assets/images/img/info-img-1.png" alt="">
                                </div>
                                <div class="info-text fl">
                                    <p>总得分最高：733.57</p>
                                    <p>浦项（张家港）不锈钢股份有限公司</p>
                                </div>
                            </div>
                            <div class="info-2">
                                <div class="info-img fl">
                                    <img src="../../assets/images/img/info-img-2.png" alt="">
                                </div>
                                <div class="info-text fl">
                                    <p>创新投入最高：300</p>
                                    <p>浦项（张家港）不锈钢股份有限公司</p>
                                </div>
                            </div>
                            <div class="info-3">
                                <div class="info-img fl">
                                    <img src="../../assets/images/img/info-img-3.png" alt="">
                                </div>
                                <div class="info-text fl">
                                    <p>创新活动最高：200</p>
                                    <p>江苏三洋新材料有限公司</p>
                                </div>
                            </div>
                            <div class="info-4">
                                <div class="info-img fl">
                                    <img src="../../assets/images/img/info-img-4.png" alt="">
                                </div>
                                <div class="info-text fl">
                                    <p>创新投入最高：300</p>
                                    <p>浦项（张家港）不锈钢股份有限公司</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-bottom">
                        <div class="title">{{ SelectedInsName }}-创新能力得分</div>
                        <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                        <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                        <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                        <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                        <div id="echarts_1" class="charts">
                            <topring :performanceEvaluation="innovationCapabilityScore" />
                        </div>
                    </div>
                </div>
                <div class="left-bottom">
                    <div class="title">{{ SelectedInsName }} - 创新活动各基础指标数据</div>
                    <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                    <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                    <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                    <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                    <div id="echarts_2" class="charts">
                        <rightradar :researchStatus="innovationActivity"
                            :pageInnovationActivityListIndexs="pageInnovationActivityListIndexs" />
                    </div>
                </div>
            </div>
            <div class="con-center fl">
                <div class="map-num">
                    <p>2024年接入企业数(个)</p>
                    <div class="num">
                        <span>0</span>
                        <span>,</span>
                        <span>0</span>
                        <span>0</span>
                        <span>0</span>
                        <span>,</span>
                        <span>1</span>
                        <span>0</span>
                        <span>9</span>
                    </div>
                </div>
                <div class="cen-top" id="map">
                    <graphbox :institutions="allcompanys" @insNameChanged="insNameChanged" />
                </div>
            </div>
            <div class="con-right fr">
                <div class="right-top">
                    <div class="title">创新成效Top5
                        <select v-model="innovationIndex">
                            <option class="option" v-for="(item, index) in innovationIndexs" :value="item?.value"
                                :label="item?.label">
                            </option>
                        </select>
                    </div>
                    <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                    <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                    <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                    <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                    <div id="echarts_4" class="charts">
                        <rightbar :pageManagementStatusList="innovationIndexsList" :innovationIndex="innovationIndex" />
                    </div>
                </div>
                <div class="right-center">
                    <div class="title">创新投入Top5
                        <select v-model="innovationInvestmentIndex">
                            <option class="option" v-for="(item, index) in pageInnovationInvestmentIndexs"
                                :value="item?.value" :label="item?.label">
                            </option>
                        </select>
                    </div>
                    <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                    <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                    <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                    <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                    <div id="echarts_5" class="charts">
                        <rightbars :pageTeamBuildingList="innovationInvestmentIndexsList"
                            :innovationInvestmentIndex="innovationInvestmentIndex" />
                    </div>
                </div>
                <div class="right-bottom">
                    <div class="title">创新活动Top5
                        <select v-model="pageInnovationActivityListIndex">
                            <option class="option" v-for="(item, index) in pageInnovationActivityListIndexs"
                                :value="item?.value" :label="item?.label">
                            </option>
                        </select>
                    </div>
                    <img src="../../assets/images/img/bj-1.png" alt="" class="bj-1">
                    <img src="../../assets/images/img/bj-2.png" alt="" class="bj-2">
                    <img src="../../assets/images/img/bj-3.png" alt="" class="bj-3">
                    <img src="../../assets/images/img/bj-4.png" alt="" class="bj-4">
                    <div id="echarts_6" class="charts">
                        <rightLine :researchOutput="InnovationActivityList"
                            :pageInnovationActivityListIndex="pageInnovationActivityListIndex" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import graphbox from './components/graphbox'
import topring from './components/topring'
import rightbar from './components/rightbar'
import rightbars from './components/rightbars'
import rightLine from './components/rightLine'
import rightradar from './components/rightradar'
import bottomindex from './components/bottomindex'
export default {
    name: "company",
    layout: 'unauthenticated',
    components: {
        graphbox, topring, rightbar, rightbars, rightLine, rightradar, bottomindex
    },
    data() {
        return {
            companys: [],
            allcompanys: [],
            SelectedInsName: '',
            innovationCapabilityScore: null,
            innovationIndexs: [
                {
                    label: '拥有有效国内知识产权数量',
                    value: 'domesticIntelProCnt'
                },
                {
                    label: '一类知识产权数量',
                    value: 'classOneIntelPropCnt'
                },
                {
                    label: '二类知识产权数量',
                    value: 'classTwoIntelPropCnt'
                },
                {
                    label: '自主研发国内知识产权授权量',
                    value: 'indepDomeIntelPropGrantedCnt'
                },
                {
                    label: 'PCT申请量',
                    value: 'pctApplyCnt'
                },
                {
                    label: '国外专利授权量',
                    value: 'foreignGrantedCnt'
                },
                {
                    label: '欧美日韩专利授权量',
                    value: 'europeGrantedCnt'
                },
                {
                    label: '其它国家和地区专利授权量',
                    value: 'otherGrantedCnt'
                },
                {
                    label: '高价值发明专利拥有量',
                    value: 'highValueInventionPatentCnt'
                },
                {
                    label: '参编标准数量',
                    value: 'stdParticipationCnt'
                },
                {
                    label: '参编国际标准数量',
                    value: 'intlStdParticipationCnt'
                },
                {
                    label: '参编国家标准数量',
                    value: 'nationalStdParticipationCnt'
                },
                {
                    label: '参编行业标准数量',
                    value: 'intlStdParticipationCnt'
                },
                {
                    label: '获得省部级以上科技奖励数',
                    value: 'provAboveSciAwardsCnt'
                },
                {
                    label: '企业技术输出合同成交额',
                    value: 'techOutputContractAmt'
                },
                {
                    label: '高新技术产品收入',
                    value: 'highTechIncome'
                },
                {
                    label: '高新技术产品收入占比',
                    value: 'highTechIncomeRate'
                },
                {
                    label: '营业收入',
                    value: 'operatingIncome'
                },
                {
                    label: '销售收入增长率',
                    value: 'salesIncomeAddedValue'
                },
                {
                    label: '净资产增长率',
                    value: 'netAssetsRate'
                },
                {
                    label: '净利润',
                    value: 'netProfit'
                },
            ],
            innovationIndex: '',
            innovationIndexsList: [],
            pageInnovationInvestmentIndexs: [
                { label: '研发经费支出', value: 'rndExpends' },
                { label: '科技人员人工费用总额', value: 'stpCosts' },
                { label: '人均研发经费支出', value: 'avgPerExpends' },
                { label: '研发经费支出占营业收入比例', value: 'rndInIncomeRate' },
                { label: '研发经费增长率', value: 'rndExpendsGrowthRate' },
                { label: '研发费用税前加计扣除', value: 'preTaxRndExpends' },
                { label: '科技融资能力', value: 'techFinancingCap' },
                { label: '纵向经费支持额度', value: 'verticalFundingSupAmt' },
                { label: '获得风险投融资额', value: 'ventureCapitalInvestment' },
                { label: '获得科技类政策性贷款', value: 'techPolicyLoans' },
                { label: '研发仪器设备原值', value: 'rndDeviceAmt' },
                { label: '新增研发仪器设备经费支出', value: 'rndDeviceCosts' },
                { label: '拥有领军科技人才数', value: 'techLeadingCnt' },
                { label: '国家级人才数', value: 'nationalTalentsCnt' },
                { label: '省部级人才数', value: 'provTalentsCnt' },
                { label: '拥有高技能人才数', value: 'highTechSkillsTalentsCnt' },
                { label: '科技人员数', value: 'techTalentsCnt' },
                { label: '科技人员占职工总数比例', value: 'techInStaffRate' },
                { label: '科技人员中具有高级职称的占比', value: 'highLevelInTechRate' },
                { label: '科技人员中研究生以上人员占比', value: 'researchersMscAboveRate' },
                { label: '科技人员持股比例', value: 'techEquityRate' },
            ],
            innovationInvestmentIndex: '',
            innovationInvestmentIndexsList: [],
            pageInnovationActivityListIndexs: [
                { label: "承担国家级项目数", value: 'nationalProjectsCnt' },
                { label: "承担省部级项目", value: 'provProjectCnt' },
                { label: "实施国家和省部级项目的配套研发经费支出", value: 'matchingProjectRndExpenses' },
                { label: "基础研发经费强度", value: 'basicRndExpenses' },
                { label: "首(台)套重大技术装备", value: 'keyTechAchievedCnt' },
                { label: "关键设备国产化率", value: 'domesticRndDeviceRate' },
                { label: "研发项目数量", value: 'rndProjectCnt' },
                { label: "自主研发项目数", value: 'independentRndProjectCnt' },
                { label: "委外研发项目数", value: 'nonIndependentRndProjectCnt' },
                { label: "委外研发项目占研发项目比例", value: 'nonIndependentRndProjectRate' },
                { label: "国家级科技创新平台数", value: 'nationalTechInnvoPlatform_cnt' },
                { label: "省部级科技创新平台数", value: 'provTechInnvoPlatformCnt' },
                { label: "科技成果转化数量", value: 'stTransCnt' },
                { label: "科技成果内部转化收益", value: 'stInnerTransAmt' },
                { label: "科技成果外部转化成效", value: 'stOuterTransAmt' },
            ],
            pageInnovationActivityListIndex: '',
            InnovationActivityList: [],
            innovationActivity: null
        }
    },
    watch: {
        SelectedInsName: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.queryscore(val)
                    }
                })
            }
        },
        innovationIndex: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.queryinnovationIndex(val)
                    }
                })
            }
        },
        innovationInvestmentIndex: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.queryinnovationInvestmentIndex(val)
                    }
                })
            }
        },
        pageInnovationActivityListIndex: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.queryInnovationActivityListIndex(val)
                    }
                })
            }
        },
    },
    mounted() {
        this.innovationIndex = this.innovationIndexs.find(item => item.label === '营业收入')?.value
        this.innovationInvestmentIndex = this.pageInnovationInvestmentIndexs.find(item => item.label === '研发经费支出')?.value
        this.pageInnovationActivityListIndex = this.pageInnovationActivityListIndexs.find(item => item.label === '承担国家级项目数')?.value
        this.init()
    },
    methods: {
        init() {
            this.$axios.get('/indicator/companyIndicator/list/companyInfoList').then(res => {
                this.companys = res.data.data
                this.SelectedInsName = res.data.data[0].companyName
            })
            const url = '/indicator/companyIndicator/list/pageCompanyInnovationAbilityScoreVOList'
            const passData = new FormData()
            passData.append('current', 1)
            passData.append('pageSize', 9999)
            passData.append('sortOrder', 'asc')
            passData.append('sortField', 'totalScore')
            this.$axios.post(url, passData).then((res) => {
                this.allcompanys = res.data.data.records
            })
        },
        insNameChanged(val) {
            this.SelectedInsName = val
        },
        queryscore(name) {
            const socialCreditCode = this.companys.find(item => item.companyName === name).socialCreditCode
            const url = '/indicator/companyIndicator/query/innovationCapabilityScore'
            const data = new FormData()
            data.append('statisticalYear', 2024)
            data.append('companyName', name)
            data.append('socialCreditCode', socialCreditCode)
            this.$axios.post(url, data).then(res => {
                this.innovationCapabilityScore = res.data.data
            })


            const url1 = '/indicator/companyIndicator/query/innovationActivity'
            const data1 = new FormData()
            data1.append('statisticalYear', 2024)
            data1.append('companyName', name)
            data1.append('socialCreditCode', socialCreditCode)
            this.$axios.post(url1, data1).then(res => {
                this.innovationActivity = res.data.data
            })


        },
        queryinnovationIndex(queryinnovationIndex) {
            const url = '/indicator/companyIndicator/list/pageInnovationResultsList'
            const pageInnovationResultsData = new FormData()
            pageInnovationResultsData.append('current', 1)
            pageInnovationResultsData.append('pageSize', 5)
            pageInnovationResultsData.append('sortOrder', 'desc')
            pageInnovationResultsData.append('sortField', queryinnovationIndex)
            this.$axios.post(url, pageInnovationResultsData).then(res => {
                this.innovationIndexsList = res.data.data.records
            })
        },
        queryinnovationInvestmentIndex(queryinnovationInvestmentIndex) {
            const url = '/indicator/companyIndicator/list/pageInnovationInvestmentList'
            const pageInnovationResultsData = new FormData()
            pageInnovationResultsData.append('current', 1)
            pageInnovationResultsData.append('pageSize', 5)
            pageInnovationResultsData.append('sortOrder', 'asc')
            pageInnovationResultsData.append('sortField', queryinnovationInvestmentIndex)
            this.$axios.post(url, pageInnovationResultsData).then(res => {
                this.innovationInvestmentIndexsList = res.data.data.records
            })
        },
        queryInnovationActivityListIndex(InnovationActivityListIndex) {
            const url = '/indicator/companyIndicator/list/pageInnovationActivityList'
            const pageInnovationResultsData = new FormData()
            pageInnovationResultsData.append('current', 1)
            pageInnovationResultsData.append('pageSize', 5)
            pageInnovationResultsData.append('sortOrder', 'asc')
            pageInnovationResultsData.append('sortField', InnovationActivityListIndex)
            this.$axios.post(url, pageInnovationResultsData).then(res => {
                this.InnovationActivityList = res.data.data.records
            })
        },
    }
};
</script>

<style scoped lang="scss">
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
    margin: 0;
    padding: 0;
}


fieldset,
img,
input,
button {
    border: none;
    padding: 0;
    margin: 0;
    outline-style: none;
}

ul,
ol {
    list-style: none;
}

input {
    padding-top: 0;
    padding-bottom: 0;

}

select,
input {
    vertical-align: middle;
}

select,
input,
textarea {
    font-size: 14px;
    margin: 0;
}

textarea {
    resize: none;
}

img {
    border: 0;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}


.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-decoration: none;
    font-weight: normal;
    font-size: 100%;
}

a {
    text-decoration: none;
}

s,
i,
em {
    font-style: normal;
    text-decoration: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.data {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/img/map_bg.png) center;
    min-width: 1366px;
}

.data>.data-title {
    width: 100%;
    height: 105px;
    padding: 30px 0 35px 0;
    box-sizing: border-box;
}

.data>.data-title>.title-center {
    width: 440px;
    height: 40px;
    box-sizing: border-box;
    border-right: 5px solid #0089ff;
    border-left: 5px solid #0089ff;
    text-align: center;
    line-height: 40px;
    font-size: 42px;
    color: #ffffff;
    letter-spacing: 2px;
    font-weight: 600;
    font-family: cursive;
}

.data>.data-title>.title-left,
.data>.data-title>.title-right {
    width: calc(50% - 220px);
    height: 3px;
    background: #0089ff;
    margin-top: 18px;
}

.data>.data-content {
    width: 100%;
    height: calc(100% - 105px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
}

.data>.data-content>.con-left {
    width: 23.4375%;
    height: 100%;
}

.data>.data-content>.con-left>.left-top {
    width: 100%;
    height: calc(75% - 20px);
    margin-bottom: 20px;
    position: relative;
}

.data>.data-content>.con-left>.left-top>.info {
    height: 62%;
    width: 100%;
    border: 1px solid #20558b;
    /*border-radius: 4px;*/
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-left>.left-top>.info>.info-title {
    width: 158px;
    height: 43px;
    background: url(../../assets/images/img/info-title.png) no-repeat;
    position: absolute;
    top: -22px;
    left: 50%;
    margin-left: -74px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 43px;
    text-align: center;
}

.data>.data-content>.con-left>.left-top>.info>.info-main {
    width: 100%;
    height: 80%;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>div {
    width: 275px;
    height: 25%;
    background: url(../../assets/images/img/info-text.png) no-repeat;
    margin: 8px auto;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>div.info-1 {
    margin-top: 40px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main .info-img {
    width: 100px;
    height: 60px;
    position: relative;
}

.data>.data-content>.con-left>.left-top>.info>.info-main .info-img>img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -24px;
    margin-top: -24px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main .info-text {
    width: 175px;
    height: 70px;
    padding-left: 8px;
    padding-top: 16px;
    box-sizing: border-box;
}

.data>.data-content>.con-left>.left-top>.info>.info-main .info-text>p:nth-child(1) {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>.info-1>.info-text>p:nth-child(2) {
    font-weight: 600;
    color: #ffff44;
    font-size: 14px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>.info-2>.info-text>p:nth-child(2) {
    font-weight: 600;
    color: #25f3e6;
    font-size: 14px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>.info-3>.info-text>p:nth-child(2) {
    font-weight: 600;
    color: #f84a4a;
    font-size: 14px;
}

.data>.data-content>.con-left>.left-top>.info>.info-main>.info-4>.info-text>p:nth-child(2) {
    font-weight: 600;
    color: #f5c847;
    font-size: 14px;
}

.data>.data-content>.con-left>.left-top>.top-bottom {
    height: calc(38% - 20px);
    width: 100%;
    background-color: rgba(0, 24, 106, 0.5);
    margin-top: 20px;
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}



.data>.data-content>.con-left>.left-bottom {
    width: 100%;
    height: 25%;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-center {
    width: 53.125%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-center>.map-num {
    width: 500px;
    height: 120px;
    position: absolute;
    top: 15px;
    left: 50px;
    z-index: 1000;
}

.data>.data-content>.con-center>.map-num>p {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.data>.data-content>.con-center>.map-num span {
    display: inline-block;
    width: 45px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    background-color: #0089ff;
    color: #fff;
    font-size: 68px;
    font-weight: 600;
    font-family: "LcdD";
    margin-top: 15px;
}

.data>.data-content>.con-center>.map-num span:nth-child(2),
.data>.data-content>.con-center>.map-num span:nth-child(6) {
    background-color: transparent;
    width: 30px;
}

.data>.data-content>.con-center>.cen-top {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}


.data>.data-content>.con-center>.cen-bottom {
    width: 100%;
    height: 25%;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-right {
    width: 23.4375%;
    height: 100%;
}

.data>.data-content>.con-right>.right-top {
    width: 100%;
    height: 32%;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-right>.right-center {
    width: 100%;
    height: calc(36% - 40px);
    margin: 20px 0;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content>.con-right>.right-bottom {
    width: 100%;
    height: 32%;
    background-color: rgba(0, 24, 106, 0.5);
    border: 1px solid #20558b;
    box-sizing: border-box;
    position: relative;
}

.data>.data-content .title {
    height: 35px;
    line-height: 35px;
    width: 100%;
    color: #fff;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    select {
        width: 56%;
        background: transparent;
        border: none;
        color: #ffffff;
        text-align: right;

        .option {
            color: #000
        }
    }
}

.data>.data-content .charts {
    width: 100%;
    height: calc(100% - 35px);
}

.data>.data-content img.bj-1 {
    position: absolute;
    left: -1px;
    top: -1px;
}

.data>.data-content img.bj-2 {
    position: absolute;
    right: -1px;
    top: -1px;
}

.data>.data-content img.bj-3 {
    position: absolute;
    right: -1px;
    bottom: -1px;
}

.data>.data-content img.bj-4 {
    position: absolute;
    left: -1px;
    bottom: -1px;
}
</style>
