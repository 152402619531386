<template>
    <div class="container">
        <div class="form">
            <div class="title">智能分析</div>
            <div class="form-item">
                <span>技术方案名称</span> <input type="text" v-model="form.name">
            </div>
            <div class="form-item">
                <span>技术方案描述信息</span> <textarea v-model="form.desc" style=" height: 200px;" />
            </div>
            <div class="form-item">
                <button class="submit" @click="submitAnalysis">提交</button>
                <button class="reset" @click="resetAnalysis()">重置</button>
            </div>
        </div>

        <div class="img">
            <div class="title">成熟度</div>
            <div class="loading" v-if="loading">
                <div>正在分析....</div>
            </div>
            <p v-else> <img v-show="img" :src="img" alt=""></p>

        </div>

        <div class="result">

            <div class="title">分析结论</div>
            <div class="loading" v-if="loading">
                <div>正在分析....</div>
            </div>
            <p v-else>{{ explanation }}</p>
        </div>
    </div>

</template>

<script>
import img1 from './启动期.jpg'
import img2 from './泡沫期.jpg'
import img3 from './低谷期.jpg'
import img4 from './爬升期.jpg'
import img5 from './成熟期.jpg'
export default {
    name: "maturity",
    layout: 'unauthenticated',

    data() {
        return {
            loading: false,
            overviewStatus: [
                { str: '启动', img: img1 },
                { str: '泡沫', img: img2 },
                { str: '低谷', img: img3 },
                { str: '爬升', img: img4 },
                { str: '成熟', img: img5 },
                { str: '启动期', img: img1 },
                { str: '泡沫期', img: img2 },
                { str: '低谷期', img: img3 },
                { str: '爬升期', img: img4 },
                { str: '成熟期', img: img5 },


            ],

            img: null,
            form: {
                name: '人工智能',
                desc: '人工智能无人机巡航算法',
            },
            explanation: ''
        };
    },
    methods: {
        submitAnalysis() {
            this.loading = true
            let params = {}
            params.name = this.form.name
            params.desc = [this.form.desc]

            this.$axios.post('/guashuai/api/technical_maturity', params).then(async res => {
                this.explanation = res.data.data.explanation
                let overview = res.data.data.overview
                let num = -1
                this.overviewStatus.map((item, index) => {
                    // console.log(overview.includes(item.str), item.str, overview);
                    if (overview.includes(item.str)) {
                        num = index
                        index >= num && (this.img = item.img)
                    } else {
                        num == -1 && (this.img = null)
                    }
                })
                this.loading = false

            }).catch(err => {
                this.loading = false

            })



        },
        resetAnalysis() {
            this.form = {
                name: '	',
                desc: '',
            }
        },
    },

}
</script>
<style lang="scss">
.container {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;

    .form {
        width: calc(50% - 40px);
        min-height: 450px;
        background-color: #ffff;
        border-radius: 10px;
        margin: 20px;
        padding: 20px;
        padding-bottom: 50px;

        .title {
            font-size: 20px;
            font-weight: 900;
            border-bottom: 1px solid #8b8a8a80;
            padding-bottom: 5px;
        }

        .form-item {
            display: flex;
            gap: 10px;
            justify-content: start;
            // align-items: center;
            margin: 10px 0;
            position: relative;

            span {
                text-align: right;
                display: inline-block;
                width: 200px;
                margin-top: 10px;
            }

            button {

                border: 1px solid #4b58be;
            }

            .submit {
                position: absolute;
                right: 80px;
                top: 10px;

                background-color: #4b58be;
                color: #fff;
            }

            .reset {
                position: absolute;
                right: 0px;
                top: 10px;
                background-color: #fff;
                color: #4b58be;
            }

        }
    }

    .img {
        width: calc(50% - 40px);
        height: 420px;
        background-color: #ffff;
        border-radius: 10px;
        margin: 20px;
        padding: 20px;
        position: relative;

        .title {
            font-size: 20px;
            font-weight: 900;
            border-bottom: 2px solid #8b8a8a80;
            padding-bottom: 5px;
        }

        .loading {
            width: 100%;
            height: 420px;
            padding-top: 30px;
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 20px;
            color: #4b58be;
        }

        img {
            width: 100%;
            height: 350px;
        }
    }

    .result {
        position: absolute;
        right: 10px;
        top: 0;
        width: calc(50% - 40px);
        height: calc(100% - 40px);
        background-color: #ffff;
        border-radius: 10px;
        margin: 20px;
        padding: 20px;

        .title {
            font-size: 20px;
            font-weight: 900;
            border-bottom: 2px solid #8b8a8a80;
            padding-bottom: 5px;
        }

        p {
            padding: 5px;
            line-height: 20px;
        }

        .loading {
            width: 100%;
            height: calc(100% - 40px);
            padding-top: 30px;
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 20px;
            color: #4b58be;
        }

    }
}
</style>