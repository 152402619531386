// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/img/icons/box_title_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "ul[data-v-2952f344]{margin:0;padding:0;list-style:none}.box_container[data-v-2952f344]{height:100%;font-family:TencentSans;display:flex;flex-direction:column}.box_container .title_bar[data-v-2952f344]{height:30px;margin-bottom:10px}.box_container .title_bar .title[data-v-2952f344]{font-size:18px;align-items:center;font-family:TencentSans;font-weight:400;background-image:-webkit-linear-gradient(top,#fff,#2080cd);-webkit-background-clip:text;color:#fff!important;display:flex}.box_container .title_bar .title .title_icon[data-v-2952f344]{width:24px;height:30px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:24px 30px;margin-right:.5em;margin-top:5px}.box_container .title_bar .options_box[data-v-2952f344]{cursor:pointer;float:right;padding-top:14px}.box_container .title_bar .options_box .options[data-v-2952f344]{height:20px;line-height:20px;display:flex}.box_container .title_bar .options_box .options>div[data-v-2952f344]{height:34px;line-height:34px;font-size:22px;padding:0 10px;text-align:center;background:linear-gradient(90deg,#2137a9,#19229b);margin-right:5px}.box_container .title_bar .options_box .options>div.current[data-v-2952f344]{background:linear-gradient(90deg,#0d4bd8,#2385ff)}.box_container ul.introduce[data-v-2952f344]{height:40px;font-size:20px;padding:.5em 0}.box_container ul.introduce>li[data-v-2952f344]{float:left;margin-right:.5em}.box_container ul.introduce>li .num[data-v-2952f344]{color:#28e0ed;padding:0 1px}.box_container ul.introduce[data-v-2952f344]:after{content:\"\";display:block;clear:both}.box_container .box_content[data-v-2952f344]{height:calc(100% - 40px);color:#fff}", ""]);
// Exports
module.exports = exports;
