<template>
    <div style="width:100%;height: 100%;" ref="GpuLine">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'GpuLine',
    async mounted() {
        this.initChart()
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr) {
            this.myChart = echarts.init(this.$refs['GpuLine']);
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(181, 181, 181, 1)'
                        },
                    },
                },
                grid: {
                    top: '15%',
                    left: '10%',
                    right: '5%',
                    bottom: '12%',
                    // containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(181, 181, 181, .3)',
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: 'rgba(181, 181, 181, 1)',
                        width: 100,
                        interval: 0,
                        rotate: 0,
                        fontSize: 12,
                    },
                    splitLine: {
                        show: false
                    },
                    boundaryGap: false,
                    data: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00']

                }],
                yAxis: [{
                    type: 'value',
                    min: 0,
                    max: 100,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(181, 181, 181, 1)',
                            opacity: 0.23
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        margin: 20,
                        textStyle: {
                            color: 'rgba(181, 181, 181, 1)',
                        },
                        formatter: '{value}%'
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [
                    {
                        name: '',
                        type: 'line',
                        symbol: 'none',
                        symbolSize: 10,
                        smooth: true,
                        lineStyle: {
                            normal: {
                                color: "rgba(50, 111, 254, 1)",
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: 'rgba(181, 181, 181, 1)',
                            }
                        },
                        itemStyle: {
                            color: "rgba(181, 181, 181, 1)",
                            borderColor: "rgba(181, 181, 181, 1)",
                            borderWidth: 2,
                        },
                        areaStyle: { // 区域样式
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgba(50, 111, 254, 1)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                                }],
                            }
                        },
                        data: [19, 14, 20, 50, 29, 50, 40, 67]
                    },
                ]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>