<template>
    <div class="box_container">
        <!-- 标题 -->
        <div class="title_bar">
            <!-- 顶部选项卡 -->
            <div class="options_box">
                <div class="options">
                    <div :class="index == i ? 'current' : ''" v-for="(o, i) in options" :key="o.name"
                        @click="tabOptionHandler(i)">{{ o.label }}</div>
                </div>
            </div>
            <!-- 顶部标题 -->
            <div class="title">
                <span class="title_icon"></span>
                <span>{{ title }}</span>
            </div>
        </div>
        <!-- 描述 -->
        <!-- <slot name="introduce"></slot> -->
        <ul class="introduce" v-if="introduces.length > 0">
            <li v-for="introduce in introduces" :key="introduce.label">
                <span class="label">{{ introduce.label }} </span>
                <span class="num">{{ introduce.value }}</span>
                <span class="unit">{{ introduce.unit }}</span>
            </li>
        </ul>
        <!-- 内容 -->
        <div class="box_content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'box',
    props: {
        title: {
            type: [String],
            default: () => {
                return ''
            },
        },
        options: {
            type: [Array],
            default: () => {
                return [];
            },
        },
        introduces: {
            type: [Array],
            default: () => {
                return []
            }
        }
    }
};
</script>

<style scoped lang="scss">
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.box_container {
    height: 100%;
    font-family: TencentSans;
    display: flex;
    flex-direction: column;

    .title_bar {
        height: 30px;
        margin-bottom: 10px;

        // 标题
        .title {
            font-size: 18px;
            align-items: center;
            font-family: TencentSans;
            font-weight: normal;
            background-image: -webkit-linear-gradient(top, #ffffff, #2080cd);
            -webkit-background-clip: text;
            color: #fff !important;
            display: flex;

            .title_icon {
                width: 24px;
                height: 30px;
                background: url(../../../assets/images/img/icons/box_title_icon.png);
                background-size: 24px 30px;
                margin-right: .5em;
                margin-top: 5px;
            }
        }

        .options_box {
            cursor: pointer;
            float: right;
            padding-top: 14px;

            .options {
                height: 20px;
                line-height: 20px;
                display: flex;

                &>div {
                    height: 34px;
                    line-height: 34px;
                    font-size: 22px;
                    padding: 0 10px;
                    text-align: center;
                    background: linear-gradient(90deg, #2137A9, #19229B);
                    margin-right: 5px;
                }

                &>div.current {
                    background: linear-gradient(90deg, #0D4BD8, #2385FF);
                }

            }
        }
    }

    ul.introduce {
        height: 40px;
        font-size: 20px;
        padding: .5em 0;

        &>li {
            float: left;
            margin-right: .5em;

            .num {
                color: #28E0ED;
                padding: 0 1px;
            }
        }
    }

    ul.introduce::after {
        content: "";
        display: block;
        clear: both;
    }

    .box_content {
        height: calc(100% - 40px);
        color: #fff;
    }
}
</style>