<template>
    <div class="centergraph" ref="centergraph">
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'graphBox',
    props: {
        institutions: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        institutions: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    this.initChart()
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        getRandomColor(totalScore) {
            let segment;
            let color;

            // 判断 totalScore 所在的分段
            if (totalScore <= 50) {
                segment = 0; // 最低分段
            } else if (totalScore <= 75) {
                segment = 1; // 次低分段
            } else if (totalScore <= 120) {
                segment = 2; // 次高分段
            } else {
                segment = 3; // 最高分段
            }

            // 根据段落选择颜色
            switch (segment) {
                case 0: // 最低分段 - 浅红色
                    color = "#FF6666"; // 浅红色
                    break;
                case 1: // 次低分段 - 浅紫色
                    color = "#9966CC"; // 浅紫色
                    break;
                case 2: // 次高分段 - 浅绿色
                    color = "#99CC66"; // 浅绿色
                    break;
                case 3: // 最高分段 - 橘黄色
                    color = "#DEB140"; // 橘黄色
                    break;
            }

            return color;
        },
        initChart() {
            this.myChart = echarts.init(this.$refs['centergraph']);
            const realdata = []
            this.institutions.map(ins => {
                let color = this.getRandomColor(ins.totalScore)
                const tempjson = {
                    "name": ins.companyName,
                    "value": 2373,
                    "symbolSize": ins.totalScore > 450 ? ins.totalScore / 1.5 : ins.totalScore,
                    "draggable": true,
                    "itemStyle": {
                        "normal": {
                            "shadowBlur": 50,
                            "shadowColor": '#000000',
                            "color": color
                        }
                    }
                }
                realdata.push(tempjson)

            })
            const option = {
                animationDurationUpdate: function (idx) {
                    // 越往后的数据延迟越大
                    return idx * 100;
                },
                animationEasingUpdate: 'bounceIn',
                color: ['#fff', '#fff', '#fff'],
                series: [{
                    type: 'graph',
                    layout: 'force',
                    force: {
                        repulsion: 500,
                        edgeLength: 10
                    },
                    roam: true,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    data: realdata
                }]
            }
            this.myChart.setOption(option);
            this.myChart.on('click', (params) => {
                this.$emit('insNameChanged', params.name)
            });

        }
    }
}
</script>

<style lang="scss" scoped>
.centergraph {
    width: 100%;
    height: 100%;
}
</style>