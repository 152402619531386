<template>
    <div class="ringgraphs" ref="ringgraphs">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'rightbars',
    props: {
        pageTeamBuildingList: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        pageTeamBuildingList: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr) {
            this.myChart = echarts.init(this.$refs['ringgraphs']);
            const xdata = []
            const ydata1 = []
            arr.map(data => {
                xdata.push(data.companyName)
                ydata1.push(data[this.innovationInvestmentIndex])
            })
            const option = {
                tooltip: { //提示框组件
                    trigger: 'axis',
                    // formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle: {
                        color: '#fff',
                        fontStyle: 'normal',
                        fontFamily: '微软雅黑',
                        fontSize: 12,
                    }
                },
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '6%',
                    top: 30,
                    padding: '0 0 10 0',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,//坐标轴两边留白
                        data: xdata,
                        axisLabel: {
                            interval: 0,
                            rotate: 8,
                            textStyle: {
                                color: '#078ceb',
                                fontStyle: 'normal',
                                fontFamily: '微软雅黑',
                                fontSize: 12,
                            }
                        },
                        axisTick: {//坐标轴刻度相关设置。
                            show: false,
                        },
                        axisLine: {//坐标轴轴线相关设置
                            lineStyle: {
                                color: '#fff',
                                opacity: 0.2
                            }
                        },
                        splitLine: { //坐标轴在 grid 区域中的分隔线。
                            show: false,
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitNumber: 5,
                        axisLabel: {
                            textStyle: {
                                color: '#a8aab0',
                                fontStyle: 'normal',
                                fontFamily: '微软雅黑',
                                fontSize: 12,
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#fff'],
                                opacity: 0.06
                            }
                        }

                    }
                ],
                series: [
                    {
                        name: '营业收入',
                        type: 'bar',
                        data: ydata2,
                        barWidth: 10,
                        barGap: 0.2,//柱间距离
                        label: {//图形上的文本标签
                            normal: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#a8aab0',
                                    fontStyle: 'normal',
                                    fontFamily: '微软雅黑',
                                    fontSize: 12,
                                },
                            },
                        },
                        itemStyle: {//图形样式
                            normal: {
                                barBorderRadius: [5, 5, 0, 0],
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 1, color: 'rgba(127, 128, 225, 0.7)'
                                }, {
                                    offset: 0.9, color: 'rgba(72, 73, 181, 0.7)'
                                }, {
                                    offset: 0.25, color: 'rgba(226, 99, 74, 0.7)'
                                }, {
                                    offset: 0, color: 'rgba(253, 200, 106, 0.7)'
                                }], false),
                            },
                        },
                    },
                    {
                        name: '研发经费支出',
                        type: 'bar',
                        data: ydata3,
                        barWidth: 10,
                        barGap: 0.2,//柱间距离
                        label: {//图形上的文本标签
                            normal: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#a8aab0',
                                    fontStyle: 'normal',
                                    fontFamily: '微软雅黑',
                                    fontSize: 12,
                                },
                            },
                        },
                        itemStyle: {//图形样式
                            normal: {
                                barBorderRadius: [5, 5, 0, 0],
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: 'rgba(255, 215, 0, 0.9)' },
                                    { offset: 0.9, color: 'rgba(255, 140, 0, 0.6)' },
                                    { offset: 0.25, color: 'rgba(255, 69, 0, 0.6)' },
                                    { offset: 0, color: 'rgba(255, 0, 0, 0.6)' }
                                ], false),
                            },
                        },
                    },
                ]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.ringgraphs {
    width: 100%;
    height: 100%;
}
</style>
      