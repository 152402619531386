// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/img/zuo_xuxian.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".left_boottom[data-v-686ac219]{width:100%;height:100%;overflow:hidden scroll}.left_boottom .left_boottom_item[data-v-686ac219]{display:flex;align-items:center;justify-content:center;padding:8px;font-size:14px;margin:10px 0}.left_boottom .left_boottom_item .orderNum[data-v-686ac219]{margin:0 16px 0 -20px}.left_boottom .left_boottom_item .info[data-v-686ac219]{margin-right:10px;display:flex;align-items:center;color:#fff}.left_boottom .left_boottom_item .info .labels[data-v-686ac219]{flex-shrink:0;font-size:12px;color:hsla(0,0%,100%,.6)}.left_boottom .left_boottom_item .info .zhuyao[data-v-686ac219]{font-size:15px}.left_boottom .left_boottom_item .info .ciyao[data-v-686ac219]{color:hsla(0,0%,100%,.8)}.left_boottom .left_boottom_item .info .warning[data-v-686ac219]{color:#e6a23c;font-size:15px}.left_boottom .left_boottom_item .inner_right[data-v-686ac219]{position:relative;height:100%;width:380px;flex-shrink:0;line-height:1;display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap}.left_boottom .left_boottom_item .inner_right .dibu[data-v-686ac219]{position:absolute;height:2px;width:104%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");bottom:-10px;left:-2%;background-size:cover}.left_boottom .left_boottom_item .inner_right .addresswrap[data-v-686ac219]{width:100%;display:flex;margin-top:8px}.left_boottom .left_boottom_item .wangguan[data-v-686ac219]{color:#1890ff;font-weight:900;font-size:15px;width:130px;flex-shrink:0}.left_boottom .left_boottom_item .time[data-v-686ac219]{font-size:12px;color:#fff}.left_boottom .left_boottom_item .address[data-v-686ac219]{font-size:12px;cursor:pointer}.left_boottom .left_boottom_item .types[data-v-686ac219]{flex-shrink:0}.left_boottom .left_boottom_item .typeRed[data-v-686ac219]{color:#fc1a1a}.left_boottom .left_boottom_item .typeGreen[data-v-686ac219]{color:#29fc29}.flex[data-v-686ac219]{display:flex}", ""]);
// Exports
module.exports = exports;
