<template>
    <div style="width:100%;height: 100%;" ref="CenterBar">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'CenterBar',
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        this.initChart()
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart() {
            this.myChart = echarts.init(this.$refs['CenterBar']);
            const xdata1 = ['政策智能匹与推送', '科技GPT智能对话', '企业质态分析', '实验室质态分析', '智能上架内容敏感检测', '智能上架内容合规性检测', '商品智能估值', '商品智能四配推荐', '发榜智能凝练填报', '榜单商品供需匹配', '揭榜方能力智能评估', '关键技术等技术类型识别', '重点支持方向指南决策建议', '揭榜技术方案成熟度评估']
            const ydata2 = Array.from({ length: 14 }, () => Math.floor(Math.random() * (38 - 12 + 1) + 12));
            const ydata1 = Array.from({ length: 14 }, () => Math.floor(Math.random() * (44 - 14 + 1) + 14));
            const ydata3 = Array.from({ length: 14 }, () => Math.floor(Math.random() * (32 - 8 + 1) + 8));

            const option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '0%',
                    top: '16%',
                    containLabel: true
                },
                legend: {
                    data: ['每天', '每月', '每年',],
                    right: 0,
                    top: 12,
                    textStyle: {
                        color: "rgba(181, 181, 181, 1)"
                    },
                    itemWidth: 12,
                    itemHeight: 10,
                    // itemGap: 35
                },
                xAxis: {
                    type: 'category',
                    data: xdata1,
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(233, 233, 233, 1)'

                        }
                    },
                    axisLabel: {
                        interval: 0,
                        rotate: 0,
                        textStyle: {
                            color: 'rgba(181, 181, 181, 1)'
                        },
                        formatter: function (value) {
                            let rowMAx = 7
                            let overValue = ''
                            for (let i = 0; i < value.length; i++) {
                                if ((i % rowMAx == 0) && (i != 0)) {
                                    overValue += '\n'
                                    overValue += value[i]
                                } else {
                                    overValue += value[i]
                                }
                            }
                            return overValue
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'white'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(233, 233, 233, 1)'
                        }
                    },
                    axisLabel: {}
                },
                series: [{
                    name: '每天',
                    type: 'bar',
                    barWidth: '7%',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(67, 207, 124, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(67, 207, 124, 0.63)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        },
                    },
                    data: ydata3
                },
                {
                    name: '每月',
                    type: 'bar',
                    barWidth: '7%',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(255, 141, 26, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(255, 141, 26, 0.63)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        }

                    },
                    data: ydata2
                },
                {
                    name: '每年',
                    type: 'bar',
                    barWidth: '7%',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(23, 124, 255, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(23, 124, 255, 0.72)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        }

                    },
                    data: ydata1
                }
                ]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>