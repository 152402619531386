<template>
    <div class="screen-wrapper scale-wrap">
        <div class="bg">
            <div class="host-body">
                <!-- 头部 s -->
                <div class="d-flex jc-center title_wrap">
                    <div class="zuojuxing"></div>
                    <div class="youjuxing"></div>
                    <div class="guang"></div>
                    <div class="d-flex jc-center">
                        <div class="title">
                            <span class="title-text">江苏省实验室指标统计</span>
                        </div>
                    </div>
                    <div class="lefttimers">
                        年份切换 : <select name="year" id="year" v-model="year">
                            <option v-for='(item, index) in years' :key="index" :value="item">{{ item }}</option>
                        </select>
                    </div>
                    <div class="timers">
                        {{ dateYear }} {{ dateWeek }} {{ dateDay }}
                    </div>
                </div>
                <!-- 头部 e-->
                <!-- 内容  s-->
                <div class="contents">
                    <div class="contetn_left">
                        <itembox class="contetn_left-top contetn_lr-item" :title="labName">
                            <LeftTop :labName="labName" :year="year" />
                        </itembox>
                        <itembox class="contetn_left-center contetn_lr-item" title="实验室年度指标总得分">
                            <LeftCenter :topTotalScore="topTotalScore" />
                        </itembox>
                        <!-- <itembox class="contetn_left-bottom contetn_lr-item" title="实验室承担纵向重大项目情况">
                            <LeftBottom :labName="labName" :year="year" />
                        </itembox> -->
                    </div>
                    <div class="contetn_center">
                        <CenterMap class="contetn_center_top" :labs="labs" :year="year"
                            @labNameChanged="(val) => labName = val" />
                        <itembox class="contetn_center-bottom" title="软硬件投入情况">
                            <select v-model="innovationIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                <option style="color: #000;" v-for="(item, index) in innovationIndexList"
                                    :value="item?.value" :label="item?.label">
                                </option>
                            </select>
                            <CenterBottom :wareList="wareList" :innovationIndex="innovationIndex" />
                        </itembox>
                    </div>
                    <div class="contetn_right">
                        <itembox class="contetn_left-bottom contetn_lr-item" title="年度人才团队建设情况">
                            <!-- <select v-model="TeamIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                <option style="color: #000;" v-for="(item, index) in TeamList" :value="item?.value"
                                    :label="item?.label">
                                </option>
                            </select> -->
                            <RightCenter :teamList="teamList" :TeamIndex="TeamIndex" />
                        </itembox>
                        <!-- <itembox class="contetn_left-bottom contetn_lr-item" title="年度横向合作情况">
                            <select v-model="ProjectIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                <option style="color: #000;" v-for="(item, index) in ProjectList" :value="item?.value"
                                    :label="item?.label">
                                </option>
                            </select>
                            <RightTop :projectList="projectList" :ProjectIndex="ProjectIndex" />
                        </itembox> -->
                        <itembox class="contetn_left-bottom contetn_lr-item" title="年度创新成果产出情况 ">
                            <select v-model="OutputIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                <option style="color: #000;" v-for="(item, index) in OutputList" :value="item?.value"
                                    :label="item?.label">
                                </option>
                            </select>
                            <RightBottom :outputList="outputList" :OutputIndex="OutputIndex" />
                        </itembox>
                    </div>
                </div>
                <!-- 内容 e -->
            </div>
        </div>
    </div>
</template>


<script>
import itembox from './components/itembox.vue'
import LeftTop from './components/LeftTop.vue'
import LeftCenter from './components/LeftCenter.vue'
import LeftBottom from './components/LeftBottom.vue'
import CenterMap from './components/CenterMap.vue'
import CenterBottom from './components/CenterBottom.vue'
import RightTop from './components/RightTop.vue'
import RightBottom from './components/RightBottom.vue'
import RightCenter from './components/RightCenter.vue'

export default {
    name: "labs",
    components: {
        itembox, CenterMap, LeftTop, LeftBottom, LeftCenter, CenterBottom, RightTop, RightBottom, RightCenter
    },
    layout: 'unauthenticated',
    data() {
        return {
            timing: null,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
            labs: [],
            year: 2024,
            years: [],
            labName: '',
            topTotalScore: [],
            wareList: [],
            innovationIndex: '',
            innovationIndexList: [
                { label: '研发服务场所面积', value: 'rndFacilityArea' },
                { label: '仪器设备累计投入（万元）', value: 'accumulatedEquipmentInvestment' },
                { label: '50万以上仪器设备投入(万元)', value: 'above50EquipmentInvestment' },
                { label: '累计拥有仪器设备(台/套)', value: 'totalEquipmentOwnership' },
                { label: '50万以上仪器设备(台/套)', value: 'above50EquipmentCnt' },
                { label: '年度研发经费投入总额(万元)', value: 'annualRndCosts' },
                { label: '团队建设经费(万元)', value: 'teamBuildingExpend' },
                { label: '仪器设备购置经费(万元)', value: 'equipmentPurchaseExpend' }],
            ProjectIndex: '',
            ProjectList: [
                { label: '成果转让数', value: 'techTransferCnt' },
                { label: '成果转让合同总金额', value: 'techTransferContractTotalAmt' },
                { label: '技术入股成果数', value: 'techEquityCnt' },
                { label: '技术入股总金额', value: 'techEquityTotalAmt' },
                { label: '技术服务总数', value: 'techServiceCnt' },
                { label: '技术服务总收入', value: 'techServiceTotalIncome' },
                { label: '技术合同登记数', value: 'techContractRegisterCnt' },
                { label: '技术合同成交额', value: 'techContractDealAmt' },
                { label: '设立开放课题项目数', value: 'openTopicProjectCnt' },
                { label: '开放课题资金', value: 'openTopicFundingAmt' },

            ],
            projectList: [],
            OutputList: [
                { label: '已形成重大战略性目标产品和技术数', value: 'topProductsTechCnt' },
                { label: '成果转化转让数', value: 'techTransferCnt' },
                { label: '在国际国内核心期刊发表论文数', value: 'papersInCoreJournalsCnt' },
                { label: '获省部级及以上科技奖励数', value: 'aboveProvTechAwardCnt' },
                { label: '发明专利申请数', value: 'inventionPatentApplicationsCnt' },
                { label: '专利申请总数', value: 'totalPatentApplicationsCnt' },
                { label: '发明专利授权数', value: 'inventionPatentGrantsCnt' },
                { label: '专利授权总数', value: 'totalPatentGrantsCnt' },
                { label: '医药新药证书数', value: 'newDrugCertForMedicCnt' },
                { label: '农药新药证书数', value: 'newDrugCertForAgriCnt' },
                { label: '兽药新药证书数', value: 'newDrugCertForVeteriCnt' },
                { label: '动植物新品种审定数', value: 'newVarietiesAnimalAndPlantCnt' },
                { label: '软件著作权数', value: 'softwareCopyrightsCnt' },
                { label: '集成电路设计版权数', value: 'integratedCircuitDesignCnt' },
                { label: '国际标准数', value: 'internationalStandardCnt' },
                { label: '国家标准数', value: 'nationalStandardCnt' },
                { label: '地方标准数', value: 'localStandardCnt' },
                { label: '行业标准数', value: 'industryStandardCnt' }
            ],
            OutputIndex: '',
            outputList: [],
            TeamList: [
                { "label": "人员总数", "value": "totalPersonnelCnt" },
                { "label": "科研人员数", "value": "researchPersonnelCnt" },
                { "label": "管理人员数", "value": "managePersonnelCnt" },
                { "label": "高级职称数", "value": "seniorProfessionalTitleCnt" },
                { "label": "博士数", "value": "doctorateCnt" },
                { "label": "硕士数", "value": "masterCnt" },
                { "label": "院士数", "value": "academicianCnt" },
                { "label": "国家重大人才工程计划数", "value": "nationalMajorTalentProjectCnt" },
                { "label": "国家高层次人才特殊支持计划数", "value": "nationalHighLevelTalentsPlanCnt" },
                { "label": "国家杰青数", "value": "nationalYoungExpertsCnt" },
                { "label": "长江学者数", "value": "yangtzeScholarsCnt" },
                { "label": "省双创人才数", "value": "provDoubleTalentsCnt" },
                { "label": "省333工程第一层次培养对象", "value": "prov333FirstLevelCnt" },
                { "label": "省333工程第二层次培养对象", "value": "prov333SecondLevelCnt" },
                { "label": "省333工程第三层次培养对象", "value": "prov333ThirdLevelCnt" },
                { "label": "江苏省创新团队计划数", "value": "provInnovationTeamPlanCnt" },
                { "label": "当年新增人员数", "value": "newlyAddedPersonnelCnt" }
            ],
            teamList: [],
            TeamIndex: ''
        };
    },
    watch: {
        year: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data = new FormData()
                    data.append('current', 1)
                    data.append('pageSize', 1000)
                    data.append('sortField', 'totalScore')
                    data.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabIndicatorsList', data).then(res => {
                        this.topTotalScore = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })


                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', this.innovationIndex)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabHardwareInvestmentList', data1).then(res => {
                        this.wareList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })

                    const data2 = new FormData()
                    data2.append('current', 1)
                    data2.append('pageSize', 1000)
                    data2.append('sortField', this.ProjectIndex)
                    data2.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabCooperativeSituationList', data2).then(res => {
                        this.projectList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })

                    const data3 = new FormData()
                    data3.append('current', 1)
                    data3.append('pageSize', 1000)
                    data3.append('sortField', this.OutputIndex)
                    data3.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabAnnualNewInnovationOutputList', data3).then(res => {
                        this.outputList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })


                    const data4 = new FormData()
                    data4.append('current', 1)
                    data4.append('pageSize', 1000)
                    // data4.append('sortField', this.TeamInde  x)
                    data4.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabTalentTeamIndicatorsList', data4).then(res => {
                        this.teamList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })
                })
            }
        },
        innovationIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabHardwareInvestmentList', data1).then(res => {
                        this.wareList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        ProjectIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabCooperativeSituationList', data1).then(res => {
                        this.projectList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        OutputIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabAnnualNewInnovationOutputList', data1).then(res => {
                        this.outputList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        TeamIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    // data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabTalentTeamIndicatorsList', data1).then(res => {
                        this.teamList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
    },
    mounted() {
        this.timeFn();
        this.init()
    },
    beforeDestroy() {
        clearInterval(this.timing);
    },
    methods: {
        init() {
            this.innovationIndex = this.innovationIndexList[0].value
            this.ProjectIndex = this.ProjectList[0].value
            this.OutputIndex = this.OutputList[0].value
            this.TeamIndex = this.TeamList[0].value
            this.$axios.get('/indicator/provinceLabIndicator/list/provinceLabInfoList').then(res => {
                this.labs = res.data.data
                const filtersLabs = res.data.data.filter(item => item.statisticalYear === Number(this.year))
                if (filtersLabs[0]?.labName) {
                    this.labName = filtersLabs[0].labName
                }
                this.years = this.getUniqueYears(this.labs)
            })

            const data = new FormData()
            data.append('current', 1)
            data.append('pageSize', 1000)
            data.append('sortField', 'totalScore')
            data.append('sortOrder', 'desc')
            this.$axios.post('/indicator/provinceLabIndicator/list/pageProvinceLabIndicatorsList', data).then(res => {
                this.topTotalScore = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
            })
        },
        getUniqueYears(data) {
            const uniqueYears = [];
            data.forEach(entry => {
                const year = entry.statisticalYear;
                if (!uniqueYears.includes(year)) {
                    uniqueYears.push(year);
                }
            });
            return uniqueYears;
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = this.formatTime(new Date(), "HH: mm: ss");
                this.dateYear = this.formatTime(new Date(), "yyyy-MM-dd");
                this.dateWeek = this.weekday[new Date().getDay()];
            }, 1000);
        },
        formatTime(time, fmt) {
            if (!time) return '';
            else {
                const date = new Date(time);
                const o = {
                    'M+': date.getMonth() + 1,
                    'd+': date.getDate(),
                    'H+': date.getHours(),
                    'm+': date.getMinutes(),
                    's+': date.getSeconds(),
                    'q+': Math.floor((date.getMonth() + 3) / 3),
                    S: date.getMilliseconds(),
                };
                if (/(y+)/.test(fmt))
                    fmt = fmt.replace(
                        RegExp.$1,
                        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
                    );
                for (const k in o) {
                    if (new RegExp('(' + k + ')').test(fmt)) {
                        fmt = fmt.replace(
                            RegExp.$1,
                            RegExp.$1.length === 1
                                ? o[k]
                                : ('00' + o[k]).substr(('' + o[k]).length)
                        );
                    }
                }
                return fmt;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.screen-wrapper {
    overflow: hidden;
    background-size: 100% 100%;
    background: #000;
    width: 100vw;
    height: 100vh;
}

.scale-wrap {
    color: #d3d6dd;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 100%;
        padding: 16px 16px 10px 16px;
        box-sizing: border-box;
        background-image: url(../../assets/images/img/pageBg.png);
        background-size: cover;
        background-position: center center;
    }

    .host-body {
        height: 100%;

        .title_wrap {
            height: 60px;
            background-image: url(../../assets/images/img/top.png);
            background-size: cover;
            background-position: center center;
            position: relative;
            margin-bottom: 4px;

            .guang {
                position: absolute;
                bottom: -26px;
                background-image: url(../../assets/images/img/guang.png);
                background-position: 80px center;
                width: 100%;
                height: 56px;
            }

            .zuojuxing,
            .youjuxing {
                position: absolute;
                top: -2px;
                width: 140px;
                height: 6px;
                background-image: url(../../assets/images/img/headers/juxing1.png);
            }

            .zuojuxing {

                left: 11%;
            }

            .youjuxing {
                right: 11%;
                transform: rotate(180deg);
            }

            .lefttimers {
                position: absolute;
                left: 20px;
                top: 30px;
                font-size: 18px;
                width: 200px;
                display: flex;
                align-items: center;

                .blq-icon-shezhi02 {
                    cursor: pointer;
                }

                select {
                    width: 56%;
                    background: transparent;
                    border: none;
                    color: #ffffff;
                    text-align: right;

                    option {
                        text-align: center;
                        color: #000
                    }
                }
            }

            .timers {
                position: absolute;
                right: 0;
                top: 30px;
                font-size: 18px;
                display: flex;
                align-items: center;

                .blq-icon-shezhi02 {
                    cursor: pointer;
                }
            }
        }

        .title {
            position: relative;
            text-align: center;
            background-size: cover;
            color: transparent;
            height: 60px;
            line-height: 46px;

            .title-text {
                font-size: 38px;
                font-weight: 900;
                letter-spacing: 6px;
                width: 100%;
                background: linear-gradient(92deg, #0072FF 0%, #00EAFF 48.8525390625%, #01AAFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }


}

.scale-wrap {
    .pagetab {
        position: absolute;
        top: -35px;
        display: flex;

        .item {
            width: 130px;
            height: 36px;
            border-radius: 18px 0px 0px 18px;
            color: #00FBF8;
            text-indent: 26px;
            line-height: 36px;
            font-size: 16px;
            margin-right: 20px;
            background: linear-gradient(to right, rgba(76, 245, 255, .5), rgba(76, 245, 255, 0));
        }
    }
}

.setting {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;

    .left_shu {
        color: #000;
        font-weight: 900;
        position: relative;
        text-indent: 10px;
        padding: 16px 0 10px 0;

        &::before {
            display: block;
            content: " ";
            height: 16px;
            width: 4px;
            border-radius: 2px;
            background: #0072FF;
            position: absolute;
            left: 0px;
        }
    }

    .setting_dislog {
        background-color: rgba(#000000, .5);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        right: 0;
        top: 0;
    }

    .setting_inner {
        box-sizing: border-box;
        background: #FFF;
        width: 340px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0;
        z-index: 1;
        color: #000000;
        box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);

        .setting_header {
            font-size: 20px;
            color: rgb(0, 0, 0);
            font-weight: 900;
            text-align: center;
            line-height: 40px;
        }

        .setting_body {
            padding: 0px 16px;
            box-sizing: border-box;
            position: relative;
        }

        .setting_item {
            font-size: 14px;
            line-height: 1.5;

            .setting_label {
                color: #555454;
            }

            .setting_label_tip {
                font-size: 12px;
                color: #838282;
            }
        }
    }

    .setting_inner {
        animation: rtl-drawer-out .3s;
    }
}


.settingShow {
    .setting_inner {
        animation: rtl-drawer-in .3s 1ms;
    }

}

.yh-setting-fade-enter-active {
    animation: yh-setting-fade-in .3s;
}

.yh-setting-fade-leave-active {

    animation: yh-setting-fade-out .3s;

}

@keyframes yh-setting-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes yh-setting-fade-out {
    0% {
        opacity: 1;

    }

    100% {
        opacity: 0;
    }
}


@keyframes rtl-drawer-in {
    0% {
        transform: translate(100%, 0)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes rtl-drawer-out {
    0% {
        transform: translate(0, 0)
    }

    100% {
        transform: translate(100%, 0)
    }
}

.contents {
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: end;

    .contetn_left,
    .contetn_right {
        width: 540px;
        height: calc(100vh - 100px);
        box-sizing: border-box;
    }

    .contetn_center {
        height: calc(100vh - 86px);
        width: 720px;
    }

    .contetn_lr-item {
        flex: 1;
    }

    .contetn_lr-item:not(:last-child) {
        margin-bottom: 10px;
    }

    .contetn_center_top {
        width: 100%;
    }

    .contetn_center {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .contetn_center-bottom {
        height: 315px;
    }

    .contetn_left,
    .contetn_right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;

    }
}


@keyframes rotating {
    0% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
    }

    50% {
        -webkit-transform: rotate(180deg) scale(1.1);
        transform: rotate(180deg) scale(1.1);
    }

    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1);
    }
}
</style>