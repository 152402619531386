<template>
    <div class="graphbox">
        <div class="bnt">
            <div class="topbnt_left fl">
                <ul>
                    <li>产业领域</li>
                    <li>技术创新</li>
                    <li>机构数量</li>

                </ul>
            </div>
            <div class="topbnt_right fr">
                <ul>
                    <li>星期五</li>
                    <li>18:00</li>
                    <li>01-26</li>
                </ul>
            </div>
            <h1 class="tith1 fl">新型研发机构各维度指标统计</h1>
        </div>
        <!-- bnt end -->
        <div class="left1 pleft1">
            <div class="lefttime">
                <h2 class="tith2">评审年份</h2>
                <div class="lefttime_text">
                    <ul>
                        <li class="bg active">2023</li>
                        <li></li>
                        <li class="bg">2022</li>
                        <li></li>
                        <li class="bg">2021</li>
                        <li></li>
                        <li class="bg">2020</li>
                        <li></li>
                        <li class="bg">2019</li>
                    </ul>
                </div>
            </div>
            <div class="plefttoday">
                <h2 class="tith2">额外特色得分情况统计</h2>
                <div class="lefttoday_tit" style=" height:8%">
                    <p class="fl">评审年份：2023</p>
                    <p class="fr">{{ SelectedInsName }}</p>
                </div>
                <div class="lefttoday_number">
                    <div class="widget-inline-box text-center fl">
                        <p>已培育上市企业数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.listedCompanyCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>已培育瞪羚企业数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.gazelleCompanyCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>已培育独角兽企业数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.unicornCompanyCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>已获批高新技术企业数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.highTechCompanyCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>已获批规上企业数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.approvedCompanyCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>获得省级以上科研平台数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.aboveProvPlatCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>获得省级以上奖项数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.aboveProvAwardCnt || 0 }}</h3>
                    </div>
                    <div class="widget-inline-box text-center fl">
                        <p>承办国际性学术活动数</p>
                        <h3 class="c11e2dd">{{ extraFeatureScore?.hostIntlAcCnt || 0 }}</h3>
                    </div>
                </div>
                <!-- lefttoday_number end -->
                <!--  lefttoday_bar end-->
            </div>
            <div class="lpeftmidbot">
                <h2 class="tith2">团队建设情况Top10</h2>
                <div id="lpeftmidbot" class="lpeftmidbotcont">
                    <rightbars :pageTeamBuildingList="pageTeamBuildingList" />
                </div>
            </div>
            <div class="lpeftbot">
                <h2 class="tith2">研究院运营总支出Top5</h2>
                <div id="lpeftbot liwp" class="lpeftbotcont">
                    <rightbar :pageManagementStatusList="totalExpendituredataList" />
                </div>
            </div>
        </div>
        <!--  left1 end -->
        <div class="left2">
            <div class="pleftbox2top">
                <h2 class="tith2">{{ SelectedInsName }}-绩效考评统计</h2>
                <div id="pleftbox2top" class="pleftbox2topcont">
                    <topring :performanceEvaluation="performanceEvaluation" />
                </div>
            </div>
            <div class="pleftbox2midd">
                <h2 class="tith2"></h2>
                <div id="pleftbox2midd" class="pleftbox2middcont"></div>
            </div>
            <div class="lpeft2bot">
                <h2 class="tith2">研究院总收入Top5</h2>
                <div class="pvr fl liwp lpeftb2otcont1" style="height:80%;">
                    <ul>
                        <li class="hot1">1 <span>{{ pageManagementStatusList[0]?.institutionName }}-{{
                            pageManagementStatusList[0]?.totalRevenue
                        }}</span> </li>
                        <li class="hot2">2 <span>{{ pageManagementStatusList[1]?.institutionName }}-{{
                            pageManagementStatusList[1]?.totalRevenue
                        }}</span></li>
                        <li class="hot3">3 <span>{{ pageManagementStatusList[2]?.institutionName }}-{{
                            pageManagementStatusList[2]?.totalRevenue
                        }}</span></li>
                        <li class="hot4">4 <span>{{ pageManagementStatusList[3]?.institutionName }}-{{
                            pageManagementStatusList[3]?.totalRevenue
                        }}</span></li>
                        <li class="hot5">5 <span>{{ pageManagementStatusList[4]?.institutionName }}-{{
                            pageManagementStatusList[4]?.totalRevenue
                        }}</span></li>
                    </ul>
                    <div id="pleftbox2bott_cont" class="lpeftb2otcont" style="height:100%;">
                    </div>
                </div>
            </div>
        </div>
        <div class="mrbox prbox">
            <div class="mrbox_top">
                <div class="mrbox_top_midd">
                    <div class="mrboxtm-mbox">
                        <h2 class="tith2 pt1">机构信息总览</h2>
                        <div class="mrboxtm-map">
                            <graphbox :institutions="institutions" @insNameChanged="insNameChanged" />
                        </div>
                    </div>
                    <!--  map end-->
                    <div class="pmrboxbottom">
                        <h2 class="tith2 pt1">孵化产出情况Top10</h2>
                        <div id="pmrboxbottom" class="pmrboxbottomcont">
                            <centerbars :pageIncubationOutputList="pageIncubationOutputList" />
                        </div>
                    </div>
                </div>
                <!-- mrbox_top_midd end -->
                <div class="mrbox_top_right">
                    <div class="pmrtop1">
                        <h2 class="tith2">机构信息总览</h2>
                        <div class="pmrtop_cont1">
                            <div class="pmrtop_contheight">
                                <div class="widget-inline-box text-center">
                                    <p>新型研发机构数</p>
                                    <h3 class="pt6 ceeb1fd">{{ institutions.length }}</h3>
                                </div>
                                <div class="widget-inline-box text-center">
                                    <p>环比变化</p>
                                    <h3 class="pt6 c24c9ff"><img src="../../assets/images/img/iconup.png" height="16" />10%
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- pmrtop  -->

                    <div class="pmrtop" style=" margin-top: 3.3%">
                        <h2 class="tith2">{{ SelectedInsName }}-研发情况统计</h2>
                        <div class="pmrtop_cont pmrtop_wid">
                            <rightradar :researchStatus="researchStatus" />
                        </div>
                    </div>
                    <!-- pmrtop  -->

                </div>
            </div>


            <div class="mrbox_bottom">
                <div class="rbottom_box1">
                    <h2 class="tith2">绩效考评统计Top5 </h2>
                    <div id="prbottom_box1" class="prbottom_box1cont">
                        <bottomring :pagePerformanceEvaluationList="pagePerformanceEvaluationList" />
                    </div>
                </div>
                <div class="rbottom_box2">
                    <h2 class="tith2">融资情况Top5</h2>
                    <div id="prbottom_box2" class="prbottom_box1cont">
                        <bottombars :pageFinancingStatusList="pageFinancingStatusList" />
                    </div>
                </div>
                <div class="rbottom_box3">
                    <h2 class="tith2 pt2">科研产出情况Top5</h2>
                    <div id="prbottom_box3" class="prbottom_box1cont">
                        <rightLine :researchOutput="researchOutput" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import graphbox from './components/graphbox'
import topring from './components/topring'
import rightbar from './components/rightbar'
import rightbars from './components/rightbars'
import centerbars from './components/centerbars'
import rightradar from './components/rightradar'
import bottomring from './components/bottomring'
import bottombars from './components/bottombars'
import rightLine from './components/rightLine'
export default {
    name: "report",
    layout: 'unauthenticated',
    components: {
        graphbox, topring, rightbar, rightbars, rightradar, centerbars, bottomring, bottombars, rightLine
    },
    data() {
        return {
            institutions: [],
            SelectedInsName: '',
            extraFeatureScore: null,
            performanceEvaluation: null,
            researchStatus: null,
            pageManagementStatusList: [],
            totalExpendituredataList: [],
            pageTeamBuildingList: [],
            pageIncubationOutputList: [],
            pagePerformanceEvaluationList: [],
            pageFinancingStatusList: [],
            researchOutput: []
        }
    },
    watch: {
        SelectedInsName: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.queryscore(val)
                    }
                })
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$axios.post('/indicator/institutionIndicator/list/institutionEvalList').then(res => {
                this.institutions = res.data.data
                if (this.institutions.length > 0) {
                    this.SelectedInsName = this.institutions[0]?.institutionName
                }

            })
            const data = new FormData()
            data.append('current', 1)
            data.append('pageSize', 10)
            data.append('sortOrder', 'asc')
            data.append('sortField', 'totalRevenue')
            this.$axios.post('/indicator/institutionIndicator/list/pageManagementStatusList', data).then(res => {
                this.pageManagementStatusList = res.data.data.records
            })
            const totalExpendituredata = new FormData()
            totalExpendituredata.append('current', 1)
            totalExpendituredata.append('pageSize', 5)
            totalExpendituredata.append('sortOrder', 'asc')
            totalExpendituredata.append('sortField', 'totalExpenditure')
            this.$axios.post('/indicator/institutionIndicator/list/pageManagementStatusList', totalExpendituredata).then(res => {
                this.totalExpendituredataList = res.data.data.records
            })

            const pageTeamBuildingListdata = new FormData()
            pageTeamBuildingListdata.append('current', 1)
            pageTeamBuildingListdata.append('pageSize', 10)
            pageTeamBuildingListdata.append('sortOrder', 'asc')
            pageTeamBuildingListdata.append('sortField', 'incrFullTimeRndStaff')
            this.$axios.post('/indicator/institutionIndicator/list/pageTeamBuildingList', pageTeamBuildingListdata).then(res => {
                this.pageTeamBuildingList = res.data.data.records
            })

            const pageIncubationOutputListdata = new FormData()
            pageIncubationOutputListdata.append('current', 1)
            pageIncubationOutputListdata.append('pageSize', 10)
            pageIncubationOutputListdata.append('sortOrder', 'asc')
            pageIncubationOutputListdata.append('sortField', 'totalRevenue')
            this.$axios.post('/indicator/institutionIndicator/list/pageIncubationOutputList', pageIncubationOutputListdata).then(res => {
                this.pageIncubationOutputList = res.data.data.records
            })

            const pagePerformanceEvaluationListdata = new FormData()
            pagePerformanceEvaluationListdata.append('current', 1)
            pagePerformanceEvaluationListdata.append('pageSize', 5)
            pagePerformanceEvaluationListdata.append('sortOrder', 'asc')
            pagePerformanceEvaluationListdata.append('sortField', 'totalScore')
            this.$axios.post('/indicator/institutionIndicator/list/pagePerformanceEvaluationList', pagePerformanceEvaluationListdata).then(res => {
                this.pagePerformanceEvaluationList = res.data.data.records
            })

            const pageFinancingStatusListdata = new FormData()
            pageFinancingStatusListdata.append('current', 1)
            pageFinancingStatusListdata.append('pageSize', 5)
            pageFinancingStatusListdata.append('sortOrder', 'asc')
            pageFinancingStatusListdata.append('sortField', 'capitalInvestmentAmt')
            this.$axios.post('/indicator/institutionIndicator/list/pageFinancingStatusList', pageFinancingStatusListdata).then(res => {
                this.pageFinancingStatusList = res.data.data.records
            })

            const researchOutputdata = new FormData()
            researchOutputdata.append('current', 1)
            researchOutputdata.append('pageSize', 5)
            researchOutputdata.append('sortOrder', 'asc')
            researchOutputdata.append('sortField', 'verticalProjectCnt')
            this.$axios.post('/indicator/institutionIndicator/list/pageResearchOutputList', researchOutputdata).then(res => {
                this.researchOutput = res.data.data.records
            })

        },
        queryscore(name) {
            const url = '/indicator/institutionIndicator/query/extraFeatureScore'
            const url1 = '/indicator/institutionIndicator/query/performanceEvaluation'
            const data = new FormData()
            data.append('evaluateYear', 2023)
            data.append('institutionName', name)
            this.$axios.post(url, data).then(res => {
                this.extraFeatureScore = res.data.data
            })
            this.$axios.post(url1, data).then(res => {
                this.performanceEvaluation = res.data.data
            })

            const data1 = new FormData()
            data1.append('evaluateYear', 2023)
            data1.append('institutionName', name)
            const url2 = '/indicator/institutionIndicator/query/researchStatus'
            this.$axios.post(url2, data).then(res => {
                this.researchStatus = res.data.data
            })

        },
        insNameChanged(val) {
            this.SelectedInsName = val
        },
        getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
    }
};
</script>


<style scoped lang="scss">
@charset "utf-8";

.graphbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background: #051655;
    font-family: 微软雅黑, MicrosoftYahei, sans-serif;
    color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre,
table,
blockquote,
input,
button,
select,
em,
textarea {
    margin: 0;
    font-weight: normal;
    margin: 0;
    padding: 0;
    list-style: none;
    font-style: normal;
}

.graphbox {
    font-family: 微软雅黑, MicrosoftYahei, sans-serif;
    color: #fff;
    background-size: 100% 100%;
    background-position: 0 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: #0E0F22;
    background-image: -webkit-linear-gradient(top, #20427a, #10152b);
    background-image: -moz-linear-gradient(top, #20427a, #10152b);
    background-image: -ms-linear-gradient(top, #20427a, #10152b);
    background-image: -o-linear-gradient(top, #20427a, #10152b);
    background-image: linear-gradient(to bottom, #20427a, #10152b);
}

.wpbox {
    width: 100%;
    height: calc(100% - 10px)
}

.bnt {
    height: 9%;
    width: 100%;
    display: inline-block;
}

.left1 {
    width: 18%;
    height: calc(100% - 10%);
    float: left;
    padding-left: 2.2%;
    text-align: center;
}

.pleft1 {
    width: 18.6%;
    float: left;
    padding-left: 2.2%;
    text-align: center;
}

.puleft {
    padding-left: 2.2%;
    width: 35.2%;
    text-align: center;
    height: 100%
}

.puleft2 {
    width: 35%;
    height: 100%;
    padding-left: .4%
}

.mr_right {
    width: 25%;
    height: 100%
}

.left2 {
    width: 18%;
    float: left;
    height: 100%
}

.mrbox {
    float: left;
    width: 79%;
    height: 100%
}

.mrbox.prbox {
    float: left;
    width: 60%;
    height: 100%
}

.mrbox_bottom {
    float: left;
    width: 100%;
    height: 28%
}

.mrbox_top_midd {
    width: 68%;
    float: left;
    height: 100%;
}

.mrbox_topmidd {
    float: left;
    width: 76%;
    padding-left: .2%;
    height: 100%;
}

.amidd_bott,
.box {
    overflow: hidden;
}

.pmidd_bott {
    width: 100%;
    height: 57.4%;
}

.mrbox_top_right {
    float: right;
    width: 29.4%;
    padding-right: 1.4%;
    height: 100%
}

.mrbox_top {
    width: 100%;
    height: 62.4%;
}

.hdmrbox_top {
    width: 100%;
    height: 100%;
}

.lefttime {
    background: url(../../assets/images/img/time.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 84%;
    height: 9.3%;
    margin-left: 6%
}

.lefttime_text {
    padding: 2% 5% 0 5%
}

.lefttime_text li {
    font-size: 12px;
    color: rgba(255, 255, 255, .9);
    width: 20%;
    float: left;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 4px
}

.lefttime_text li.bg {
    background: rgba(0, 183, 238, .1);
}

.lefttime_text li.active {
    background: rgba(0, 183, 238, .6);
    color: #fff;
}

.lefttoday {
    background: url(../../assets/images/img/left1box.png);
    background-repeat: no-repeat;
    background-position: -3px 2px;
    width: 350px;
    height: 584px;
    margin: 0 auto;
}

.lefttoday_tit {
    overflow: hidden;
    padding: 1.9% 5% .2%;
    height: 6%;
    position: relative;
}

.lefttoday_tit.height {
    height: 12%;
}

.lefttoday_number {
    overflow: hidden;
    height: 74%;
    width: 91%;
    margin: 1% 4%;
    background: rgba(1, 202, 217, .2);
}

.lefttoday_tit p.fl {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    left: 5%;
    top: 22%
}

.lefttoday_tit p.fr {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    right: 5%;
    top: 25%
}

.lefttoday_tit p.fm {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    left: 40%;
    top: 25%
}

.lefttoday_tit.height.ht {
    height: 16%;
}

.lefttoday_tit.height p.fl {
    position: absolute;
    left: 5%;
    top: 15%;
}

.lefttoday_tit.height p.fr {
    position: absolute;
    left: 5%;
    top: 65%;
    right: auto;
}

.lefttoday_bar ul {
    position: relative;
    width: 100%;
    height: 100%;
}

.lefttoday_bar li {
    color: #fff;
    position: absolute;
    border-radius: 50%;
    font-size: 12px;
    overflow: hidden;
    font-weight: normal;
    text-align: center;
    line-height: 140%
}

.lefttoday_bar li span {
    padding-top: 30%;
    display: inline-block;
}

.c1 {
    background: #ac3ff2
}

.c2 {
    background: #ffff00
}

.c3 {
    background: #0078ff
}

.c4 {
    background: #9cff00
}

.c5 {
    background: #ff6c00
}

.c6 {
    background: #77b5fb
}

.big0 {
    width: 10px;
    height: 10px
}

.big1 {
    width: 20px;
    height: 20px
}

.big2 {
    width: 30px;
    height: 30px
}

.big3 {
    width: 40px;
    height: 40px
}

.big4 {
    width: 50px;
    height: 50px
}

.big5 {
    width: 60px;
    height: 60px
}

.big6 {
    width: 70px;
    height: 70px;
}

.leftclass {
    background: url(../../assets/images/img/leftb1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 350px;
    height: 291px;
}

.leftbox2_table {
    background: url(../../assets/images/img/leftbox2.png);
    background-repeat: no-repeat;
    background-position: -2px -2px;
    width: 354px;
    height: 680px;
}

.left2_table {
    width: 91%;
    margin-left: 5%;
    font-size: 12px;
    height: 83.6%;
    overflow: hidden;
}

.hdleft2_table {
    width: 91%;
    margin-left: 5%;
    font-size: 12px;
    height: 91.6%;
    overflow: hidden;
}

.left2_table li {
    background: rgba(1, 202, 217, .2) url(../../assets/images/img/icosjx.png) no-repeat top left;
    position: relative;
    overflow: hidden;
    padding: 2% 6%;
    color: rgba(255, 255, 255, .7);
    line-height: 150%
}

.left2_table li b {
    color: rgba(255, 255, 255, 1);
    font-weight: normal;
}

.left2_table li p.fl {
    width: 80%;
    overflow: hidden;
}

.left2_table li p.fr {
    position: absolute;
    right: 5%;
    top: -20%;
}

.yellow {
    color: #fff45c
}

.green {
    color: #00c2fd
}

.left2_table li.bg {
    background: rgba(0, 255, 255, .4) url(../../assets/images/img/icosjx.png) no-repeat top left;
}

.mrbox_tr_box {
    background: url(../../assets/images/img/rbox1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 354px;
    height: 291px;
}

.mrboxtm-mbox {
    background: url(../../assets/images/img/midtop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 63.6%;
}

.mrboxtm-b1 {
    background: url(../../assets/images/img/mbox1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 460px;
    height: 233px;
    float: left;
}

.mrboxtm-b2 {
    background: url(../../assets/images/img/mbox2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 330px;
    height: 233px;
    float: right;
}

/* .mrbox_tr_box{background:url(../../assets/images/img/rbox1.png);background-size: 100% 100%; background-repeat: no-repeat;background-position: top center; width:350px; height:680px;} */

.hdmrboxtm-mbox {
    background: url(../../assets/images/img/hdbj.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 61.6%;
}

.rbottom_box1 {
    background: url(../../assets/images/img/b-rbox2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 33.5%;
    height: 89.4%;
    float: left;
}

.rbottom_box2 {
    background: url(../../assets/images/img/bbox2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 33.5%;
    height: 89.4%;
    float: left;
    margin-left: .8%
}

.rbottom_box3 {
    background: url(../../assets/images/img/b-rbox2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 30%;
    height: 89.4%;
    float: left;
    margin-left: 1%
}

.prbottom_box1cont {
    margin-left: 5.2%;
    width: 90.6%;
    height: 82%;
    margin-top: 1.8%;
}

.prbottom_box2cont {
    margin-left: 5.2%;
    width: 90.6%;
    height: 82%;
    margin-top: 1.8%;
}

.prbottom_box3cont {
    margin-left: 5.2%;
    width: 90.6%;
    height: 82%;
    margin-top: 1.8%;
}

.tith2 {
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding-top: 1.9%;
    font-weight: normal;
    letter-spacing: 2px;
    font-weight: normal;
    overflow: hidden;
    color: #ffffff;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.topbnt_left {
    width: 33%
}

.topbnt_left ul {
    padding-top: 38px;
    padding-left: 10%;
    width: 100%
}

.topbnt_left li {
    background: url(../../assets/images/img/bnt.png) center;
    font-size: 14px;
    line-height: 33px;
    background-repeat: no-repeat;
    width: 18%;
    height: 35px;
    float: left;
    text-align: center;
    margin-left: 1%
}

.topbnt_left li.active,
.topbnt_right li.active {
    background: url(../../assets/images/img/bntactive.png) no-repeat center;
}

.topbnt_left li a {
    text-decoration: none;
    color: #fff;
}

.tith1 {
    width: 33%;
    text-align: center;
    padding-top: 16px;
    font-weight: bold;
    letter-spacing: 8px;
    font-size: 36px;
    color: #ffffff;
}

.topbnt_right {
    padding-top: 2%;
    padding-right: 2.5%;
    width: 27%
}

.topbnt_right li {
    background: url(../../assets/images/img/bnt.png) center;
    font-size: 14px;
    line-height: 33px;
    background-repeat: no-repeat;
    width: 22%;
    height: 35px;
    float: right;
    text-align: center;
    margin-right: 1%
}

.topbnt_right li a {
    text-decoration: none;
    color: #fff;
}

.pt1 {
    padding-top: 1.3%;
}

.pt2 {
    padding-top: 2.2%;
}

.pt3 {
    padding-top: 3.3%;
}

.pt6 {
    padding-top: 6px;
}

.pt17 {
    padding-top: 17px;
}

.pt14 {
    padding-top: 14px
}

.pt12 {
    padding-top: 12px
}

.pt20 {
    padding-top: 22px;
}

/* .box_pad{ margin: 3px 20px; } */

.mrboxtm-map {
    width: 96%;
    margin-left: 2%;
    margin-top: 1%;
    height: 90%;
    position: relative;
}

.hdmrboxtm-map {
    background: url(../../assets/images/img/hdmap.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 90%;
    margin-left: 4%;
    margin-top: 1%;
    height: 66%;
    position: relative;
}

.mrboxtm-map li {
    width: 23px;
    height: 22px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 12px;
    position: absolute;
}

.mrboxtm-map li.bluer {
    background-image: url(../../assets/images/img/blue_rico.png);
}

.mrboxtm-map li.redr {
    background-image: url(../../assets/images/img/red_rico.png);
}

.ricontop {
    width: 29px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    bbackground-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    font-size: 12px;
    margin-right: 5px;
    font-style: normal;
    display: inline-block;
}

i.bluer {
    background-image: url(../../assets/images/img/ricon2.png);
}

i.redr {
    background-image: url(../../assets/images/img/ricon1.png);
}

.font14 p.fl,
.font14 p.fr {
    color: #fff;
    font-size: 14px;
}

.mrtop1 {
    background: rgba(1, 202, 217, .2);
    overflow: hidden;
    margin: 4px 15px;
}

.widget-inline-box {
    text-align: center;
    color: rgba(255, 255, 255, .9);
    width: 50%;
    padding: 10% 0;
    text-align: center;
    font-size: 12px;
    float: left;
    overflow: hidden;
}

.widget-inline-box h3 {
    font-size: 22px;
    font-weight: 100;
    font-weight: normal;
}

.ceeb1fd {
    color: #eeb1fd
}

.c24c9ff {
    color: #24c9ff
}

.cffff00 {
    color: #ffff00
}

.c11e2dd {
    color: #11e2dd
}

.text-muted {
    font-size: 12px;
    color: #789ce0;
}

.text-muted img {
    vertical-align: middle;
    margin: 0 3px
}

.mrtop2 {
    margin: 4px 15px;
    padding: 20px 0;
    background: rgba(1, 202, 217, .2);
    width: 305px
}

.tith4 {
    font-size: 12px;
    text-align: center;
}

.mrtop3 {
    margin: 4px 15px;
    padding: 20px 0;
    background: rgba(1, 202, 217, .2);
    width: 305px
}

.mrboxtm-b1wp {
    margin: 4px 25px;
    padding: 20px 0;
    background: rgba(1, 202, 217, .2);
    width: 415px;
    overflow: hidden;
}

.mrboxtm_text {
    overflow: hidden;
    padding-left: 12px;
    padding-bottom: 10px;
}

.mrbtext_info {
    background: rgba(1, 202, 217, .2);
    font-weight: normal;
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, .7);
    float: left;
    margin: 2px 0 10px 8px;
    width: 140px;
}

.mrbtext_info b {
    font-weight: normal;
    font-size: 18px;
}

.lefttoday_number .widget-inline-box {
    width: 25%
}

.aleftboxttop {
    background: url(../../assets/images/img/leftb1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 99%;
    height: 27.5%;
    margin-bottom: 1%
}

.aleftboxttop .lefttoday_number {
    background: none;
}

.aleftboxttop .widget-inline-box {
    width: 24.2%;
    margin: 0 .4%;
    background: rgba(1, 202, 217, .2);
    padding: 16% 0;
    height: 100%;
    font-size: 10px;
}

.aleftboxtmidd {
    background: url(../../assets/images/img/aleftboxtmidd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 98.5%;
    height: 29.4%;
    margin-bottom: 1%
}

.aleftboxtmiddcont {
    width: 91%;
    height: 66%;
    margin-left: 4.4%;
    margin-top: 1%;
}

.aleftboxtbott {
    background: url(../../assets/images/img/aleftboxtbott.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 40%
}

.aleftboxtbott_cont {
    width: 91%;
    height: 72.6%;
    margin-top: 1.8%;
    margin-left: 4.4%;
}

.aleftboxtbott_cont2 {
    width: 89.9%;
    height: 86.6%;
    margin-top: 2.8%;
    margin-left: 5%;
}

.aleftboxtbott_contr {
    width: 89.9%;
    height: 90.6%;
    margin-top: 1.8%;
    margin-left: 5%;
}

.amiddboxttop {
    overflow: hidden;
    background: url(../../assets/images/img/amiddboxttop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 99%;
    height: 52.1%;
}

.amiddboxttop_map {
    background: url(../../assets/images/img/img.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    width: 90%;
    height: 86%;
    margin: 1.4% 5% 0;
}

.hot_map {
    width: 90%;
    height: 86%;
    margin: 1.4% 5% 0;
}

.amidd_bott {
    width: 100%;
    height: 38%;
    padding-top: .8%
}

.amiddboxttop_map span {
    background: url(../../assets/images/img/camera.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 24px;
    height: 19px;
    display: inline-block;
    position: absolute;
}

.amiddboxttop_map span.camera_l {
    background: url(../../assets/images/img/camera_l.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 24px;
    height: 19px;
    display: inline-block;
    position: absolute;
}

.amiddboxtbott1 {
    background: url(../../assets/images/img/amiddboxtbott1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 48%;
    height: 91.6%;
    margin-left: .5%;
    margin-right: 1%
}

.amiddboxtbott1content {
    width: 91%;
    height: 86.5%;
    margin-left: 4.8%;
    margin-top: 1.6%;
}

.amiddboxtbott1content2 {
    width: 91%;
    height: 80%;
    margin-left: 4.8%;
    margin-top: .6%;
}

.amiddboxtbott2 {
    background: url(../../assets/images/img/amiddboxtbott2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    float: right;
    width: 49%;
    height: 91.8%;
    margin-right: .9%;
}

.amiddboxtbott2content {
    width: 91.8%;
    height: 86%;
    margin-left: 4.4%;
    margin-top: 1.6%;
}

.arightboxtop {
    background: url(../../assets/images/img/arightboxtop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 51.9%;
}

.arightboxbott {
    background: url(../../assets/images/img/arightboxbott.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 35%;
    margin-top: 3%
}

.arightboxbottcont {
    width: 91%;
    margin-top: 1%;
    margin-left: 5%;
    height: 79%;
}

.arightboxbottcont2 {
    width: 90%;
    margin-top: 1.6%;
    margin-left: 5.3%;
    height: 85.5%;
}

.plefttoday {
    background: url(../../assets/images/img/pleft1middt.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 96%;
    height: 30%;
    margin-top: .4%;
}

.plefttoday .widget-inline-box {
    width: 48%;
    padding: 1.5% 0
}

.lpeftmidbot {
    background: url(../../assets/images/img/pleft1middb.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 206.8%;
    height: 27.6%;
    margin-top: 3.4%;
}

.lpeftmidbotcont {
    width: 90.1%;
    margin-top: 2%;
    margin-left: 5.2%;
    height: 82%;
}

.lpeftbot {
    background: url(../../assets/images/img/pleft1middb.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 95.8%;
    height: 27.6%;
    margin-top: 3.4%;
}

.lpeftbotcont {
    width: 90.1%;
    margin-top: 2%;
    margin-left: 5.2%;
    height: 82%;
}

.pleftbox2top {
    background: url(../../assets/images/img/pleftbox2top.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 96.8%;
    height: 35.2%;
    margin-top: 1%;
}

.pleftbox2topcont {
    width: 90.1%;
    margin-top: 2%;
    margin-left: 5%;
    height: 88%
}

.pleftbox2midd {
    background-color: transparent;
    width: 96.8%;
    height: 24.6%;
    margin-top: 3.8%;
}

.pleftbox2middcont {
    width: 90.1%;
    margin-top: 2%;
    margin-left: 5.2%;
    height: 82%;
    overflow: hidden;
}

.lpeft2bot {
    background: url(../../assets/images/img/pleft1middb.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 97.8%;
    height: 24.6%;
    margin-top: 4.2%;
}

.lpeftb2otcont1 {
    width: 100%;
    height: 100%;
}

.hdrightboxtop {
    background: url(../../assets/images/img/hdbjr.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 87%;
}

.lpeftb2otcont {
    width: 90.1%;
    margin-top: 2%;
    margin-left: 5.2%;
    height: 82%;
}

.pmrboxbottom {
    background: url(../../assets/images/img/pmiddboxmidd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    width: 100%;
    height: 32.9%;
    margin-top: 1.8%;
}

.pmrboxbottomcont {
    width: 94.1%;
    margin-top: 1.1%;
    margin-left: 3.1%;
    height: 76%;
}

.mrbox_bottom_bott {
    width: 100%;
    height: 27%;
    margin-top: 1.8%;
}

.pmrtop {
    background: url(../../assets/images/img/prighttop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 56.6%;
}

.pmrtop_contheight {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pmrtop1 {
    background: url(../../assets/images/img/prighttop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 40.4%;
}

.pmrtop_cont1 {
    height: 87%;
    width: 100%;
    overflow: hidden;

    p {
        font-size: 20px;
    }
}

.pmrmiddcont {
    width: 98.1%;
    margin-top: 1.1%;
    margin-left: 3.1%;
    height: 64%;
}

.pmrtop_contheight .widget-inline-box {
    padding: 5% 0;
}

.lefttoday_bar {
    height: 56%;
    width: 100%;
}

.pmrtop_cont {
    width: 90.6%;
    height: 86%;
    margin: 2.2% 0 0 5.1%
}

.pmrtop_wid .widget-inline-box {
    width: 33%;
}

.pulefttop {
    background: url(../../assets/images/img/pulefttop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 29%;
}

.puleftboxtmidd {
    background: url(../../assets/images/img/puleftmidd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 28.6%;
    margin-top: 1%
}

.puleftboxtbott {
    background: url(../../assets/images/img/puleftbott.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 28%;
    margin-top: 1%
}

.pumiddboxttop1 {
    background: url(../../assets/images/img/pumiddtop1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 47.7%;
    height: 100%;
    padding-left: 1.4%;
}

.pumiddboxttop2 {
    background: url(../../assets/images/img/pumiddtop2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 47.7%;
    height: 100%;
    margin-left: 1.4%;
}

.pmiddboxtbott {
    background: url(../../assets/images/img/pumiddbott.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 98.4%;
    height: 27.8%;
    margin-top: 2%
}

.purightboxtop {
    background: url(../../assets/images/img/purighttop.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 29%;
}

.purightboxmidd {
    background: url(../../assets/images/img/purightmidd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 28%;
    margin-top: 1.8%
}

.purightboxbott {
    background: url(../../assets/images/img/purightbott.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 28%;
    margin-top: 2.2%
}

.purightboxtopcont {
    width: 90.2%;
    height: 77%;
    margin-left: 5%;
}

.purightboxmiddcont {
    width: 90.2%;
    height: 77%;
    margin-left: 5%;
}

.purightboxbottcont {
    width: 90.2%;
    height: 77%;
    margin-left: 5%;
}

.pumiddboxtbott1cont {
    width: 62%;
    height: 100%;
    margin-left: 3%;
}

.tith2 span {
    display: inline-block;
    float: left;
    width: 40%;
    color: #ffffff;
}

.pbox {
    width: 100%;
    height: 76%;
}

.pulefttoday_bar {
    width: 46.7%;
    height: 100%;
    margin-left: 2.5%;
}

.pulefttoday_bar2 {
    width: 46.7%;
    height: 100%;
    margin-right: 2.5%;
}

.puleftboxtmidd1 {
    width: 46.7%;
    height: 100%;
    margin-left: 2.5%;
}

.puleftboxtbott1 {
    width: 62.7%;
    height: 100%;
    margin-left: 2.5%;
}

.puleftboxtbott2 {
    width: 30.7%;
    height: 100%;
    margin-right: 2.5%;
}

.puleft2height {
    width: 96%;
    height: 88%;
    margin-left: 3%;
}

.puleftbox2bott_cont {
    width: 100%;
    height: 100%;
}

.pulefttoday_bar,
.puleftboxtbott2,
.pumiddboxtbott2 {
    background: rgba(1, 202, 217, .2);
}

.puleftboxtbott2 .widget-inline-box {
    width: 100%;
    margin: 0;
    padding: 2% 0
}

.pumiddboxttop1 .widget-inline-box {
    width: 45%;
    background: rgba(1, 202, 217, .2);
    margin-left: 2%;
    margin-bottom: 1.5%;
    height: 38%;
}

.pumiddboxttop1 .widget-inline-box p {
    padding-top: 30%;
}

.f30 {
    font-size: 40px !important;
    margin: 10% 0
}

.pumiddboxtbott2 {
    width: 30.9%;
    height: 100%;
    margin-right: 2.5%;
}

.pumiddboxtbott2 .widget-inline-box {
    width: 100%;
    margin: 0;
    text-align: center;
}

.pumiddboxtbott2 .widget-inline-box p {
    padding-top: 19%
}

.pumiddboxttop2_cont {
    width: 90%;
    margin-left: 4.5%;
    margin-top: 0;
    height: 85.4%;
    overflow: hidden;
    text-align: left;
}

.pumiddboxttop2_cont ul {
    height: 100%;
}

.pumiddboxttop2_cont li {
    background: rgba(1, 202, 217, .2) url(../../assets/images/img/hot.png) no-repeat 12px 12px;
    height: 13.8%
}

.pumiddboxttop2_cont li p.text_l {
    line-height: 160%;
    width: 95%;
    overflow: hidden;
    padding-left: 10%;
}

.pumiddboxttop2_cont li p.text_r {
    text-align: right;
    width: 99%;
    height: 40%;
}

.pumiddboxttop2_cont li.bg {
    background: rgba(0, 255, 255, .4) url(../../assets/images/img/hot.png) no-repeat 12px 12px;
}

.pvr {
    position: relative;
}

.pvr ul {
    position: absolute;
    left: 11%;
    top: 13%;
}

.pvr ul li {
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    margin-top: 82%;
    border-radius: 2px;
    font-size: 12px;
    display: block;
    color: #fff;
    z-index: 1111
}

.hot1 {
    background-color: #ff0000
}

.hot2 {
    background-color: #ff7200
}

.hot3 {
    background-color: #ffbd5e
}

.hot4 {
    background-color: #b3b3b3
}

.hot5 {
    background-color: #597a9f
}

.liwp ul li {
    margin-top: 79%;
    position: relative;

    span {
        position: absolute;
        left: 40px;
        height: 16px;
    }

}

.liwp ul {

    li:nth-of-type(1) span {
        width: 200px;
        background-color: #ff0000;
    }

    li:nth-of-type(2) span {
        width: 180px;
        background-color: #ff7200;
    }

    li:nth-of-type(3) span {
        width: 160px;
        background-color: #ffbd5e;
    }

    li:nth-of-type(4) span {
        width: 150px;
        background-color: #b3b3b3;
    }

    li:nth-of-type(5) span {
        width: 140px;
        background-color: #597a9f;
    }
}

.hdtop ul li {
    margin-top: 95%;
}

.pulefttoday_bar2 ul {
    position: absolute;
    left: 4.7%;
    top: 8%;
}

.pulefttoday_bar2 ul li {
    margin-top: 110%;
}

.tlbox {
    overflow: hidden;
    height: 74%;
    width: 91%;
    margin: 1% 4%;
    background: rgba(1, 202, 217, .2);
    font-size: 12px;
}

.tlbox p.text {
    padding-left: 3%;
}

.tlbox p.text span {
    width: 27.8%;
    color: rgba(255, 255, 255, .6);
    display: inline-block;
    text-align: left;
}

.tlbox ul {
    height: 100%
}

.tlbox li {
    height: 20%;
    padding-top: 5.6%;
}

.tlbox p.rwith {
    width: 90%;
    height: 10px;
    background: #4ab4ff;
    margin: 2% 5%;
}

.tlbox span.rwith_img {
    height: 10px;
    background: #f8e19a;
    float: left;
    display: inline-block;
}

.tlbox p.text span.w12 {
    width: 28%;
    text-align: left;
}

.tlbox p.bgc3 {
    background: #f19ec2
}

.tlbox span.qgc2 {
    background: #7ecef4
}

.tlbox p.bgc2 {
    background: #99b0f7
}

.tlbox span.qgc3 {
    background: #cce198
}

.tlbox p.text span.tr {
    text-align: right;
    width: 10%;
    padding-right: 3%;
}

.tlbox li span i {
    width: 14px;
    height: 6px;
    display: inline-block;
    margin-right: 3px;
}

.ricon1 {
    background: #f8e19a;
}

.ricon2 {
    background: #7ecef4;
}

.ricon3 {
    background: #f19ec2;
}

.tricon1 {
    background: #4ab4ff;
}

.tricon2 {
    background: #99b0f7;
}

.tricon3 {
    background: #cce198;
}

.hdwid {
    width: 49.6%;
}



/*  交通分析*/
</style>

