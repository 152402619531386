<template>
    <div style="width:100%;height: 100%;" ref="CenterReverseBar">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'CenterReverseBar',
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        this.initChart()
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart() {
            this.myChart = echarts.init(this.$refs['CenterReverseBar']);
            const xdata1 = ['政策智能匹与推送', '科技GPT智能对话', '商品智能估值', '商品智能四配推荐']
            const ydata2 = Array.from({ length: 4 }, () => Math.floor(Math.random() * (38 - 12 + 1) + 12));
            const ydata1 = Array.from({ length: 4 }, () => Math.floor(Math.random() * (44 - 14 + 1) + 14));
            const ydata3 = Array.from({ length: 4 }, () => Math.floor(Math.random() * (32 - 8 + 1) + 8));
            const lineMap = {
                '1': 'path://M59 10C61.7614 10 64 7.76142 64 5C64 2.23858 61.7614 0 59 0C57.3026 0 55.8027 0.845838 54.8989 2.13902L0 4L0 6L54.8989 7.86098C55.8027 9.15416 57.3026 10 59 10Z',
                '2': 'path://M81 10C83.7614 10 86 7.76142 86 5C86 2.23858 83.7614 0 81 0C79.3195 0 77.8325 0.829107 76.926 2.10059L0 4L0 6L76.926 7.89941C77.8325 9.17089 79.3195 10 81 10Z',
                '3': 'path://M128 10C130.761 10 133 7.76142 133 5C133 2.23858 130.761 0 128 0C126.323 0 124.838 0.825967 123.931 2.09337L0 4L0 6L123.931 7.90663C124.838 9.17403 126.323 10 128 10Z',
                '4': 'path://M218 10C220.761 10 223 7.76142 223 5C223 2.23858 220.761 0 218 0C216.346 0 214.879 0.803307 213.969 2.0411L0 4L0 6L213.969 7.9589C214.879 9.19669 216.346 10 218 10Z',
                '5': 'path://M357 10C359.761 10 362 7.76142 362 5C362 2.23858 359.761 0 357 0C355.354 0 353.894 0.795267 352.983 2.02251L0 4L0 6L352.983 7.97749C353.894 9.20473 355.354 10 357 10Z'
            }
            let _this = this
            const option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '0%',
                    top: '0%',
                    containLabel: true
                },
                legend: {
                    icon: 'circle',
                    data: ['每天', '每月', '每年',],
                    right: 0,
                    top: 0,
                    textStyle: {
                        color: "rgba(181, 181, 181, 1)"
                    },
                    itemWidth: 12,
                    itemHeight: 10,
                },
                xAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(233, 233, 233, 1)'
                        }
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'category',
                    data: xdata1,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'white'
                        }
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(233, 233, 233, 1)'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        rotate: 0,
                        textStyle: {
                            color: 'rgba(181, 181, 181, 1)'
                        },
                        formatter: function (value) {
                            let rowMAx = 7
                            let overValue = ''
                            for (let i = 0; i < value.length; i++) {
                                if ((i % rowMAx == 0) && (i != 0)) {
                                    overValue += '\n'
                                    overValue += value[i]
                                } else {
                                    overValue += value[i]
                                }
                            }
                            return overValue
                        }
                    },
                },
                series: [{
                    name: '每天',
                    type: "pictorialBar",
                    barWidth: '10%',
                    barGap: '80%',
                    symbol: (params) => {
                        let type = _this.calcLinePer(params, ydata3)
                        return lineMap[type]
                    },
                    symbolSize: ['100%', '100%'],
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                                offset: 0,
                                color: 'rgba(124, 201, 41, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(135, 209, 18, 0.2)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        },
                    },
                    data: ydata3
                },
                {
                    name: '每月',
                    type: "pictorialBar",
                    barWidth: '10%',
                    barGap: '80%',
                    symbol: (params) => {
                        let type = _this.calcLinePer(params, ydata2)
                        return lineMap[type]
                    },
                    symbolSize: ['100%', '100%'],
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                                offset: 0,
                                color: 'rgba(255, 141, 26, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(255, 141, 26, 0.2)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        }

                    },
                    data: ydata2
                },
                {
                    name: '每年',
                    type: "pictorialBar",
                    barWidth: '10%',
                    barGap: '80%',
                    symbol: (params) => {
                        let type = _this.calcLinePer(params, ydata1)
                        return lineMap[type]
                    },
                    symbolSize: ['100%', '100%'],
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                                offset: 0,
                                color: 'rgba(23, 124, 255, 1)'
                            }, {
                                offset: 1,
                                color: 'rgba(43, 135, 255, 0.2)'
                            }]),
                            barBorderRadius: [12, 12, 0, 0],
                        }
                    },
                    data: ydata1
                }
                ]
            };
            this.myChart.setOption(option);
        },
        calcLinePer(num, arr) {
            let max = Math.max(...arr)
            let per = (num / max).toFixed(1)
            if (0 <= per < 0.2) return '1'
            if (0.2 <= per < 0.4) return '2'
            if (0.4 <= per < 0.6) return '3'
            if (0.6 <= per < 0.8) return '4'
            if (0.8 <= per <= 1.0) return '5'

        }
    }
}
</script>