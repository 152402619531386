<template>
    <div class="algorank">
        <div class="algoranktitle">
            <div class="algoranktitleitem">每天</div>
            <div class="algoranktitleitem">每年</div>
            <div class="algoranktitleitem">每月</div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '70%' }"><span class="val">310</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">6533</span>
                    <label>发榜智能凝练填报</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '70%' }"><span class="val">1242</span></div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '60%' }"><span class="val">210</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">5533</span>
                    <label>榜单商品供需匹配</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '68%' }"><span class="val">1042</span></div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '59%' }"><span class="val">110</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">5533</span>
                    <label>揭榜方能力智能评估</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '41%' }"><span class="val">980</span></div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '38%' }"><span class="val">100</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">4200</span>
                    <label>关键技术等技术类型识别</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '35%' }"><span class="val">880</span></div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '32%' }"><span class="val">98</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">3800</span>
                    <label>重点支持方向指南决策建议</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '32%' }"><span class="val">560</span></div>
        </div>
        <div class="algoranklist">
            <div class="algoranklistitem" :style="{ '--rankper': '28%' }"><span class="val">98</span></div>
            <div class="algoranklistitem">
                <div class="centertext">
                    <span class="num">2470</span>
                    <label>揭榜技术方案成熟度评估</label>
                </div>
            </div>
            <div class="algoranklistitem" :style="{ '--rankper': '25%' }"><span class="val">56</span></div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'AlgoRank',
    data() {
        return {
            myChart: null
        }
    },
}
</script>

<style lang="scss" scoped>
.algorank {
    width: 100%;
    height: 100%;

    .algoranktitle {
        display: flex;
        height: 20px;
        gap: 12px;

        .algoranktitleitem {
            flex: 1;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;

            &:nth-of-type(1) {
                text-align: right;
                color: rgba(121, 72, 234, 1);
            }

            &:nth-of-type(2) {
                text-align: center;
                color: rgba(102, 102, 102, 1);
            }

            &:nth-of-type(3) {
                text-align: left;
                color: rgba(135, 209, 18, 1);
            }
        }
    }

    .algoranklist {
        display: flex;
        gap: 12px;
        margin-top: 8px;
        padding-bottom: 1px;
        padding-left: 30px;
        padding-right: 30px;
        align-items: center;

        .algoranklistitem {
            flex: 1;
            opacity: 1;
            border-radius: 15px;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(255, 255, 255, 1);
            position: relative;

            &:nth-of-type(1) {
                height: 24px;
                text-align: right;
                background: rgba(121, 72, 234, 0.1);
                border: 1px solid rgba(121, 72, 234, 0.5);

                .val {
                    z-index: 99;
                    position: absolute;
                    right: 10px;
                }
            }

            &:nth-of-type(1)::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 24px;
                width: var(--rankper);
                border-radius: 15px;
                background: rgba(121, 72, 234, 1);
                z-index: 10;
            }

            &:nth-of-type(2) {
                height: 28px;
                line-height: 12px;
                text-align: center;
                background: rgba(23, 124, 255, 0.1);
                border: 1px solid rgba(23, 124, 255, 0.5);

                .centertext {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .num {
                        font-size: 12px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        color: rgba(23, 124, 255, 1);
                    }

                    label {
                        font-size: 10px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        color: rgba(102, 102, 102, 1);
                    }
                }
            }


            &:nth-of-type(3) {
                height: 24px;
                text-align: left;
                background: rgba(135, 209, 18, 0.1);
                border: 1px solid rgba(135, 209, 18, 1);

                .val {
                    z-index: 99;
                    position: absolute;
                    left: 10px;
                }
            }

            &:nth-of-type(3)::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                width: var(--rankper);
                background: rgba(135, 209, 18, 1);
                z-index: 10;
                border-radius: 15px;
            }
        }
    }
}
</style>