<template>
    <div style="width:100%;height: 100%;" ref="rightCenter">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'rightTop',
    props: {
        teamList: {
            type: Array,
            default: () => []
        },
        TeamIndex: {
            type: String,
            default: ''
        }
    },
    watch: {
        teamList: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr) {
            this.myChart = echarts.init(this.$refs['rightCenter']);
            const xdata1 = []
            const ydata2 = []
            const ydata1 = []
            arr.map(data => {
                xdata1.push(data.projectCenterName)
                if (data.jobType === '专职') {
                    ydata2.push(data[this.TeamIndex])
                } else {
                    ydata1.push(data[this.TeamIndex])
                }
            })
            const xdata2 = [...new Set(xdata1)]
            const option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        xAxisIndex: [0],
                        start: 20,
                        end: 45,
                        showDetail: false
                    },
                ],
                grid: {
                    left: '2%',
                    right: '4%',
                    bottom: '14%',
                    top: '16%',
                    containLabel: true
                },
                legend: {
                    data: ['双聘', '专职',],
                    right: 10,
                    top: 12,
                    textStyle: {
                        color: "#fff"
                    },
                    itemWidth: 12,
                    itemHeight: 10,
                    // itemGap: 35
                },
                xAxis: {
                    type: 'category',
                    data: xdata2,
                    axisLine: {
                        lineStyle: {
                            color: 'white'

                        }
                    },

                    axisLabel: {
                        interval: 0,
                        rotate: 10,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'white'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)'
                        }
                    },
                    axisLabel: {}
                },
                series: [{
                    name: '双聘',
                    type: 'bar',
                    barWidth: '15%',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#fccb05'
                            }, {
                                offset: 1,
                                color: '#f5804d'
                            }]),
                            barBorderRadius: 12,
                        },
                    },
                    data: ydata1
                },
                {
                    name: '专职',
                    type: 'bar',
                    barWidth: '15%',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#8bd46e'
                            }, {
                                offset: 1,
                                color: '#09bcb7'
                            }]),
                            barBorderRadius: 11,
                        }

                    },
                    data: ydata2
                }
                ]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>

<style lang="scss" scoped>
.rightbar {
    width: 100%;
    height: 100%;
}
</style>