<template>
    <div class="rightradar" ref="rightradar">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'topring',
    props: {
        researchStatus: {
            type: Object,
            default: () => { }
        },
        pageInnovationActivityListIndexs: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        researchStatus: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== null) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(researchStatus) {
            this.myChart = echarts.init(this.$refs['rightradar']);
            const dataname = []
            const value1 = []
            this.pageInnovationActivityListIndexs.map((item) => {
                dataname.push(item.label)
                value1.push(researchStatus[item.value])
            })

            const indicator = []
            for (var i = 0; i < dataname.length; i++) {
                indicator.push({
                    name: dataname[i],
                    max: Math.max(...value1)
                })
            }
            const option = {
                color: ["rgba(245, 166, 35, 1)"],
                radar: {
                    center: ["50%", "50%"],
                    radius: "70%",
                    startAngle: 90,
                    splitNumber: 4,
                    shape: "circle",
                    splitArea: {
                        areaStyle: {
                            color: ["transparent"]
                        }
                    },
                    name: {
                        formatter: '{value}',
                        textStyle: {
                            fontSize: 10, //外圈标签字体大小
                            color: '#ffffff' //外圈标签字体颜色
                        }
                    },
                    axisLabel: {
                        show: false,
                        fontSize: 18,
                        color: "#fff",
                        fontStyle: "normal",
                        fontWeight: "normal"
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "white"//
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "white"//
                        }
                    },
                    indicator: indicator
                },
                series: [{
                    name: "",
                    type: "radar",
                    symbol: "circle",
                    symbolSize: 10,
                    areaStyle: {
                        "normal": {
                            "color": "rgba(245, 166, 35, 0.4)"
                        }
                    },
                    itemStyle: {
                        color: 'rgba(245, 166, 35, 1)',
                        borderColor: 'rgba(245, 166, 35, 0.3)',
                        borderWidth: 10,
                    },
                    lineStyle: {
                        normal: {
                            type: "dashed",
                            color: "rgba(245, 166, 35, 1)",
                            width: 2
                        }
                    },
                    data: [
                        value1
                    ]
                }]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.rightradar {
    width: 100%;
    height: 100%;
}
</style>
      