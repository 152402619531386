<template>
    <div style="width:100%;height: 100%;" ref="centermap"></div>
</template>


<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: "centermap",
    props: {
        projectCenters: {
            type: Array,
            default: () => []
        },
        year: {
            type: Number,
            default: 2023
        }
    },
    watch: {
        projectCenters: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.initChart(val, this.year)
                    }
                })
            }
        },
        year: {
            handler: function (val) {
                this.$nextTick(() => {
                    if (this.projectCenters.length !== 0) {
                        this.initChart(this.projectCenters, val)
                    }
                })
            }
        },
    },
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr, year) {
            this.myChart = echarts.init(this.$refs['centermap']);
            const filtersLabs = arr.filter(item => item.statisticalYear === Number(this.year))
            const nodes = [...this.transformData(filtersLabs, year)]
            const option = {
                backgroundColor: 'transparent',
                animationDurationUpdate: function (idx) {
                    return idx * 100;
                },
                animationEasingUpdate: 'bounceIn',
                color: ['#fff', '#fff', '#fff'],
                series: [{
                    type: 'graph',
                    layout: 'force',
                    force: {
                        repulsion: 400,
                    },
                    roam: true,
                    label: {
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: '{b}',
                            fontSize: 16,
                            fontStyle: '600',
                        }
                    },
                    data: nodes,
                    color: ['#e8b842', '#41b1ef', '#667aed', '#347fbb', '#772cdc',
                        '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0']
                }]
            }
            this.myChart.setOption(option);
            this.myChart.on('click', (params) => {
                this.$emit('projectCenterNameChanged', params.name)
            });
        },
        transformData(data, year) {
            const transformdata = data.map(entry => {
                let color = this.getRandomColor()
                return {
                    "name": entry.projectCenterName,
                    "value": 110,
                    "symbolSize": 110,
                    "draggable": true,
                    "itemStyle": {
                        "normal": {
                            "borderColor": color,
                            "color": color
                        }
                    },

                };
            });
            return transformdata
        },
        getRandomColor() {
            // 生成随机的红、绿、蓝色值
            var r = Math.floor(Math.random() * 256);
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);

            // 将RGB值转换成十六进制，并确保两位数
            var rHex = r.toString(16).padStart(2, '0');
            var gHex = g.toString(16).padStart(2, '0');
            var bHex = b.toString(16).padStart(2, '0');

            // 返回RGB颜色代码
            return "#" + rHex + gHex + bHex;
        }
    }

}
</script>

<style lang="scss" scoped>
.centermap {
    margin-bottom: 30px;

    .maptitle {
        height: 60px;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        box-sizing: border-box;

        .titletext {
            font-size: 28px;
            font-weight: 900;
            letter-spacing: 6px;
            background: linear-gradient(92deg,
                    #0072ff 0%,
                    #00eaff 48.8525390625%,
                    #01aaff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0 10px;
        }

        .zuo,
        .you {
            background-size: 100% 100%;
            width: 29px;
            height: 20px;
            margin-top: 8px;
        }

        .zuo {
            background: url(../../../assets/images/img/xiezuo.png) no-repeat;
        }

        .you {
            background: url(../../../assets/images/img/xieyou.png) no-repeat;
        }
    }

    .mapwrap {
        height: 548px;
        width: 100%;
        // padding: 0 0 10px 0;
        box-sizing: border-box;
        position: relative;

        .quanguo {
            position: absolute;
            right: 20px;
            top: -46px;
            width: 80px;
            height: 28px;
            border: 1px solid #00eded;
            border-radius: 10px;
            color: #00f7f6;
            text-align: center;
            line-height: 26px;
            letter-spacing: 6px;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 237, 237, 0.5),
                0 0 6px rgba(0, 237, 237, 0.4);
        }
    }
}
</style>