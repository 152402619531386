// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/img/xiezuo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/img/xieyou.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".centermap[data-v-9d4e80bc]{margin-bottom:30px}.centermap .maptitle[data-v-9d4e80bc]{height:60px;display:flex;justify-content:center;padding-top:10px;box-sizing:border-box}.centermap .maptitle .titletext[data-v-9d4e80bc]{font-size:28px;font-weight:900;letter-spacing:6px;background:linear-gradient(92deg,#0072ff,#00eaff 48.8525390625%,#01aaff);-webkit-background-clip:text;-webkit-text-fill-color:transparent;margin:0 10px}.centermap .maptitle .you[data-v-9d4e80bc],.centermap .maptitle .zuo[data-v-9d4e80bc]{background-size:100% 100%;width:29px;height:20px;margin-top:8px}.centermap .maptitle .zuo[data-v-9d4e80bc]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.centermap .maptitle .you[data-v-9d4e80bc]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.centermap .mapwrap[data-v-9d4e80bc]{height:548px;width:100%;box-sizing:border-box;position:relative}.centermap .mapwrap .quanguo[data-v-9d4e80bc]{position:absolute;right:20px;top:-46px;width:80px;height:28px;border:1px solid #00eded;border-radius:10px;color:#00f7f6;text-align:center;line-height:26px;letter-spacing:6px;cursor:pointer;box-shadow:0 2px 4px rgba(0,237,237,.5),0 0 6px rgba(0,237,237,.4)}", ""]);
// Exports
module.exports = exports;
