<template>
    <div style="width:100%;height: 100%;" ref="lefttop">
        <ul class="user_Overview flex">
            <li class="user_Overview-item" style="color: #00fdfa">
                <div class="user_Overview_nums allnum ">
                    {{ data.innovativeOutputScore }}
                </div>
                <p>创新成果产出</p>
            </li>
            <li class="user_Overview-item" style="color: #07f7a8">
                <div class="user_Overview_nums online">
                    {{ data.undertakingMajorProjectsScore }}
                </div>
                <p>承担重大项目</p>
            </li>
            <li class="user_Overview-item" style="color: #e3b337">
                <div class="user_Overview_nums offline">
                    {{ data.basicInvestmentScore }}
                </div>
                <p>软硬件投入</p>
            </li>
            <li class="user_Overview-item" style="color: #f5023d">
                <div class="user_Overview_nums laramnum">
                    {{ data.talentTeamBuildingScore }}
                </div>
                <p>人才团队建设</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "leftTop",
    props: {
        labName: {
            type: String,
            default: ''
        },
        year: {
            type: Number,
            default: 2023
        }
    },
    watch: {
        labName: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.init()
                    }
                })
            }
        },
        year: {
            handler: function (val) {
                this.$nextTick(() => {
                    if (this.labName !== '') {
                        this.init()
                    }
                })
            }
        },
    },
    data() {
        return {
            data: {
                basicInvestmentScore: 0,
                talentTeamBuildingScore: 0,
                undertakingMajorProjectsScore: 0,
                innovativeOutputScore: 0
            }
        }
    },
    methods: {
        init() {
            const data = new FormData()
            data.append('labName', this.labName)
            data.append('statisticalYear', this.year)
            this.$axios.post('/indicator/provinceLabIndicator/query/jiangsuProvinceIndicators', data).then(res => {
                this.data.basicInvestmentScore = res.data.data.basicInvestmentScore
                this.data.talentTeamBuildingScore = res.data.data.talentTeamBuildingScore
                this.data.undertakingMajorProjectsScore = res.data.data.operationConstructionScore
                this.data.innovativeOutputScore = res.data.data.innovativeOutputScore
            })
        }
    }

}
</script>


<style lang='scss' scoped>
.user_Overview {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;

    li {
        flex: 1;

        p {
            text-align: center;
            height: 16px;
            font-size: 16px;
        }

        .user_Overview_nums {
            width: 100px;
            height: 100px;
            text-align: center;
            line-height: 100px;
            font-size: 22px;
            margin: 50px auto 30px;
            background-size: cover;
            background-position: center center;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            &.bgdonghua::before {
                animation: rotating 14s linear infinite;
            }
        }

        .allnum {

            // background-image: url("../../assets/img/left_top_lan.png");
            &::before {
                background-image: url(../../../assets/images/img/left_top_lan.png);

            }
        }

        .online {
            &::before {
                background-image: url(../../../assets/images/img/left_top_lv.png);

            }
        }

        .offline {
            &::before {
                background-image: url(../../../assets/images/img/left_top_huang.png);

            }
        }

        .laramnum {
            &::before {
                background-image: url(../../../assets/images/img/left_top_hong.png);

            }
        }
    }
}
</style>