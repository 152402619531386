// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/img/titles/zuo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".lr_titles[data-v-4e645d99]{box-sizing:border-box;position:absolute;left:0;top:0;width:100%;height:100%}.lr_titles .border-box-content[data-v-4e645d99]{box-sizing:border-box;padding:6px 16px 0}.lr_titles .item_title[data-v-4e645d99]{height:38px;line-height:38px;width:100%;color:#31abe3;text-align:center;position:relative;display:flex;align-items:center;justify-content:center}.lr_titles .item_title .you[data-v-4e645d99],.lr_titles .item_title .zuo[data-v-4e645d99]{width:58px;height:14px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.lr_titles .item_title .you[data-v-4e645d99]{transform:rotate(180deg)}.lr_titles .item_title .title-inner[data-v-4e645d99]{font-weight:900;letter-spacing:2px;background:linear-gradient(92deg,#0072ff,#00eaff 48.8525390625%,#01aaff);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.lr_titles .item_title_content[data-v-4e645d99]{height:calc(100% - 38px)}.dv-border-box-13[data-v-4e645d99],.lr_titles .item_title_content_def[data-v-4e645d99]{width:100%;height:100%}.dv-border-box-13[data-v-4e645d99]{position:relative}.dv-border-box-13 .dv-border-svg-container[data-v-4e645d99]{position:absolute;width:100%;height:100%;top:0;left:0}.dv-border-box-13 .border-box-content[data-v-4e645d99]{position:relative;width:100%;height:100%}", ""]);
// Exports
module.exports = exports;
