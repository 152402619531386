<template>
    <div style="width:100%;height: 100%;padding-left: 14px;">
        <div v-for="(item, index) in rankList" :key="index" class="rank">
            <div v-if="index === 0" class="text"> <span class="index"><img
                        src="../../../assets/images/dashboard/icon_rank1.png" alt="icon_rank1"></span>
                <span class="label" :title="item.label">{{ item.label }}</span>
            </div>
            <div v-else-if="index === 1" class="text"> <span class="index"><img
                        src="../../../assets/images/dashboard/icon_rank2.png" alt="icon_rank2"></span><span
                    class="label" :title="item.label">{{
            item.label }}</span></div>
            <div v-else-if="index === 2" class="text"> <span class="index"><img
                        src="../../../assets/images/dashboard/icon_rank3.png" alt="icon_rank3"></span>
                <span class="label" :title="item.label">{{ item.label }}</span>
            </div>
            <div v-else class="text"> <span class="index">{{ index + 1 }}</span> <span class="label" :title="item.label">{{
            item.label }}</span>
            </div>
            <div class="bar" :style="{
            '--percent': calculatePercentage(88, item.value, [88, 70, 60, 50, 46, 42, 36, 28])
        }"></div>
            <div class="num">{{ item.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CallRank',
    data() {
        return {
            rankList: [
                {
                    label: '政策智能匹与推送',
                    value: '88'
                }, {
                    label: '科技GPT智能对话',
                    value: '70'
                }, {
                    label: '企业质态分析',
                    value: '60'
                }, {
                    label: '实验室质态分析',
                    value: '50'
                }, {
                    label: '科技计划项目申报查重',
                    value: '46'
                }, {
                    label: '智能上架内容敏感检测',
                    value: '42'
                }, {
                    label: '智能上架内容合规性检测',
                    value: '36'
                }, {
                    label: '智能上架内容合规性检测',
                    value: '28'
                },
            ]
        }
    },
    methods: {
        calculatePercentage(maxValue, value, array) {
            const max = Math.max(...array);
            return (value / max);
        }
    }
}
</script>

<style lang="scss" scoped>
.rank {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .text {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(153, 153, 153, 1);
        height: 26px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        .index {
            width: 26.38px;
            height: 16px;
            display: inline-block;
            text-align: center;

            img {
                width: 16.38px;
                height: 16px;

            }
        }

        .label {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 23.17px;
            color: rgba(102, 102, 102, 1);
        }
    }

    .bar {
        margin-left: 20px;
        background-color: transparent;
        height: 16px;
        width: calc(100% - 200px - 60px);
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            background-color: rgba(110, 153, 255, 1);
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            width: calc((100%) * var(--percent));
        }
    }

    .num {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(102, 102, 102, 1);
        margin-left: 10px;
    }
}
</style>