<template>
    <div class="rightradar" ref="rightradar">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'topring',
    props: {
        researchStatus: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        researchStatus: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== null) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(researchStatus) {
            this.myChart = echarts.init(this.$refs['rightradar']);
            const dataname = ['转让知识产权数', '许可知识产权数', '在研在孵项目数量']
            const value1 = [researchStatus?.transferIntelPropCnt, researchStatus?.licenseIntelPropCnt, researchStatus?.projectCnt]
            const num = researchStatus?.researchExpenses

            const indicator = []
            for (var i = 0; i < dataname.length; i++) {
                indicator.push({
                    name: dataname[i],
                    max: Math.max(...value1)
                })
            }
            const option = {
                title: {
                    text: [`{span|总支出：${num}}`].join(''),
                    bottom: 'bottom',
                    left: 'center',
                    textStyle: {
                        rich: {
                            span: {
                                fontSize: 40,
                                color: '#8552BF'
                            }
                        }
                    }
                },
                radar: {
                    center: ["50%", "50%"],
                    radius: "60%",
                    splitArea: {
                        areaStyle: {
                            color: ['transparent'].reverse(),
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#fff"
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#ccc"
                        }
                    },
                    name: {
                        formatter: function (name) {
                            const i = dataname.indexOf(name)
                            return `{name1|${value1[i]}}\n{name3|${name}}`
                        },
                        lineHeight: 20,
                        rich: {
                            name1: {
                                color: '#ffffff',
                                align: 'center'
                            },
                            name2: {
                                color: 'red',
                                align: 'center'
                            },
                            name3: {
                                color: '#ffffff',
                                align: 'center',
                            }
                        }
                    },
                    indicator: indicator
                },

                series: [{
                    name: "余大大",
                    type: "radar",
                    symbol: "none",
                    areaStyle: {
                        normal: {
                            color: 'rgb(151,115,91)',
                        }
                    },
                    itemStyle: {
                        color: 'rgb(151,115,91)',
                    },
                    lineStyle: {
                        width: 0
                    },
                    data: [value1]
                }]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.rightradar {
    width: 100%;
    height: 100%;
}
</style>
      