<template>
    <div style="width:100%;height: 100%;padding: 0px 27px 35px 0px;" ref="leftbottom">
        <ul class="left_boottom">
            <li class="left_boottom_item" v-for="(item, i) in list" :key="i">
                <span class="orderNum doudong">{{ i + 1 }}</span>
                <div class="inner_right">
                    <div class="dibu"></div>
                    <div class="flex">
                        <div class="info">
                            <span class="labels">项目类型名称：</span>
                            <span class="contents zhuyao doudong wangguan">
                                {{ item.projectTypeName }}</span>
                        </div>
                        <div class="info">
                            <span class="labels">科技计划级别：</span>
                            <span class="contents " style="font-size: 12px">
                                {{ item.techPlanLevel }}</span>
                        </div>
                    </div>

                    <div class="info addresswrap">
                        <span class="types doudong typeGreen">{{ item.labName }}</span>
                    </div>

                    <div class="info addresswrap">
                        <span class="labels">总经费：</span>
                        <span class="contents ciyao" style="font-size: 12px">
                            {{ item.totalBudget }}</span>
                        &nbsp; &nbsp;&nbsp;&nbsp;
                        <span class="labels">政府拨款金额：</span>
                        <span class="contents ciyao" style="font-size: 12px">
                            {{ item.govGrantAmt }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "leftTop",
    props: {
        labName: {
            type: String,
            default: ''
        },
        year: {
            type: Number,
            default: 2023
        }
    },
    watch: {
        labName: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== '') {
                        this.init()
                    }
                })
            }
        },
        year: {
            handler: function (val) {
                this.$nextTick(() => {
                    if (this.labName !== '') {
                        this.init()
                    }
                })
            }
        },
    },
    data() {
        return {
            list: []
        }
    },
    methods: {
        init() {
            const data = new FormData()
            data.append('labName', this.labName)
            data.append('statisticalYear', this.year)
            this.$axios.post('/indicator/provinceLabIndicator/query/jiangsuProvinceLabMajorProjects', data).then(res => {
                this.list = res.data.data
            })
        }
    }

}
</script>


<style lang='scss' scoped>
.left_boottom {
    width: 100%;
    height: 100%;
    overflow: hidden scroll;

    .left_boottom_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        font-size: 14px;
        margin: 10px 0;

        .orderNum {
            margin: 0 16px 0 -20px;
        }

        .info {
            margin-right: 10px;
            display: flex;
            align-items: center;
            color: #fff;

            .labels {
                flex-shrink: 0;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.6);
            }

            .zhuyao {
                font-size: 15px;
            }

            .ciyao {
                color: rgba(255, 255, 255, 0.8);
            }

            .warning {
                color: #e6a23c;
                font-size: 15px;
            }
        }

        .inner_right {
            position: relative;
            height: 100%;
            width: 380px;
            flex-shrink: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .dibu {
                position: absolute;
                height: 2px;
                width: 104%;
                background-image: url(../../../assets/images/img/zuo_xuxian.png);
                bottom: -10px;
                left: -2%;
                background-size: cover;
            }

            .addresswrap {
                width: 100%;
                display: flex;
                margin-top: 8px;
            }
        }

        .wangguan {
            color: #1890ff;
            font-weight: 900;
            font-size: 15px;
            width: 130px;
            flex-shrink: 0;
        }


        .time {
            font-size: 12px;
            // color: rgba(211, 210, 210,.8);
            color: #fff;
        }

        .address {
            font-size: 12px;
            cursor: pointer;
            // @include text-overflow(1);
        }

        .types {
            flex-shrink: 0;
        }

        .typeRed {
            color: #fc1a1a;
        }

        .typeGreen {
            color: #29fc29;
        }
    }
}

.flex {
    display: flex;
}
</style>