<template>
    <div style="width:100%;height: 100%;" ref="RingRank">
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'RingRank',
    data() {
        return {
            myChart: null
        }
    },
    async mounted() {
        this.initChart()
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart() {
            this.myChart = echarts.init(this.$refs['RingRank']);
            let m2R2Data = [
                { value: 335, legendname: '政策智能匹与推送', name: "政策智能匹与推送", itemStyle: { color: "#177CFF" } },
                { value: 180, legendname: '科技GPT智对话', name: "科技GPT智对话", itemStyle: { color: "#87D112" } },
                { value: 120, legendname: '企业质态分析', name: "企业质态分析", itemStyle: { color: "#08B1EA" } },
                { value: 80, legendname: '实验室质态分析', name: "实验室质态分析", itemStyle: { color: "#F0BA41" } },
                { value: 40, legendname: '科技计划项目申报查重', name: "科技计划项目申报查重", itemStyle: { color: "#F8673F" } },
            ]
            let _this = this
            const option = {
                tooltip: {
                    trigger: 'item',
                    show: false
                },
                legend: {
                    type: "scroll",
                    orient: 'vertical',
                    right: '0%',
                    align: 'right',
                    top: 'middle',
                    icon: "none",
                    itemGap: 24,
                    textStyle: {
                        rich: {
                            name: {
                                fontSize: 14,
                                color: "rgba(102, 102, 102, 1)",
                            },
                            percent: {
                                width: 36,
                                fontSize: 14,
                                color: function (params) {
                                    const { color } = _this.calculatePercentage(m2R2Data, params.name);
                                    return color;
                                },
                            },
                        }
                    },
                    formatter(name) {
                        const { per } = _this.calculatePercentage(m2R2Data, name)
                        return `{name|${name}:}{percent|${Math.floor(per)}%}`
                    },
                    height: 350
                },
                series: [
                    {
                        name: '标题',
                        type: 'pie',
                        center: ['25%', '50%'],
                        radius: ['10%', '65%'],
                        clockwise: false, //饼图的扇区是否是顺时针排布
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: true,
                                position: 'outter',
                                formatter: function (parms) {
                                    return parms.data.legendname
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 5,
                                length2: 3,
                                smooth: true,
                            }
                        },
                        data: m2R2Data
                    }
                ]
            };
            this.myChart.setOption(option);
        },
        calculatePercentage(m2R2Data, name) {
            let totalValue = m2R2Data.reduce((total, item) => total + item.value, 0);
            let targetItem = m2R2Data.find(item => item.name === name);

            if (totalValue !== 0 && targetItem) {
                let percentage = (targetItem.value / totalValue) * 100;
                return {
                    per: percentage,
                    color: targetItem.itemStyle.color
                };
            } else {
                return {
                    per: 0,
                    color: targetItem.itemStyle.color
                };
            }
        }
    }
}
</script>