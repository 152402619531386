<template>
    <div class="centergraph" ref="centergraph">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'graphBox',
    props: {
        institutions: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        institutions: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    this.initChart()
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        initChart() {
            this.myChart = echarts.init(this.$refs['centergraph']);
            const realdata = []
            this.institutions.map(ins => {
                let color = this.getRandomColor()
                const tempjson = {
                    "name": ins.institutionName,
                    "value": 2373,
                    "symbolSize": this.getRandomIntInclusive(20, 120),
                    "draggable": true,
                    "itemStyle": {
                        "normal": {
                            "shadowBlur": 100,
                            "shadowColor": color,
                            "color": color
                        }
                    }
                }
                realdata.push(tempjson)

            })
            const option = {
                animationDurationUpdate: function (idx) {
                    // 越往后的数据延迟越大
                    return idx * 100;
                },
                animationEasingUpdate: 'bounceIn',
                color: ['#fff', '#fff', '#fff'],
                series: [{
                    type: 'graph',
                    layout: 'force',
                    force: {
                        repulsion: 500,
                        edgeLength: 10
                    },
                    roam: true,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    data: realdata
                }]
            }
            this.myChart.setOption(option);
            this.myChart.on('click', (params) => {
                this.$emit('insNameChanged', params.name)
            });

        }
    }
}
</script>
      
<style lang="scss" scoped >
.centergraph {
    width: 100%;
    height: 100%;
}
</style>
      