<template>
    <div class="bottomgraph" ref="bottomgraph">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'bottomring',
    props: {
        pagePerformanceEvaluationList: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        pagePerformanceEvaluationList: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val.length !== 0) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(arr) {
            this.myChart = echarts.init(this.$refs['bottomgraph']);
            const data = []
            arr.map(item => {
                data.push({
                    name: item.institutionName,
                    value: item.totalScore
                })
            })
            const arrName = getArrayValue(data, "name");
            const sumValue = 100;
            const optionData = getData(data)

            function getArrayValue(array, key) {
                var key = key || "value";
                var res = [];
                if (array) {
                    array.forEach(function (t) {
                        res.push(t[key]);
                    });
                }
                return res;
            }


            function getData(data) {
                var res = {
                    series: [{
                        name: "大环",
                        type: 'gauge',
                        splitNumber: 2,
                        radius: '82%',
                        center: ['50%', '45%'],
                        startAngle: 90,
                        endAngle: -270,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        }
                    }

                    ],
                    yAxis: []
                };
                for (let i = 0; i < data.length; i++) {
                    res.series.push({
                        name: '',
                        type: 'pie',
                        clockWise: true,
                        z: 2,
                        hoverAnimation: false,
                        radius: [76 - i * 15 + '%', 68 - i * 15 + '%'],
                        center: ["50%", "45%"],
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value;
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: [{
                            value: data[i].value,
                            name: data[i].name
                        }, {
                            value: sumValue - data[i].value,
                            name: '',
                            itemStyle: {
                                color: "rgba(0,0,0,0)",
                                borderWidth: 0
                            },
                            tooltip: {
                                show: false
                            },
                            label: {
                                show: false
                            },
                            hoverAnimation: false
                        }]
                    });
                    res.yAxis.push(data[i].name);
                }
                return res;
            }

            const option = {
                color: ['orange', 'pink', 'brown', 'gray', 'cyan'],
                legend: {
                    type: 'scroll',
                    show: true,
                    data: arrName,
                    bottom: 6,
                    left: 'center',
                    textStyle: {
                        color: '#a8aab0',
                        fontStyle: 'normal',
                        fontFamily: '微软雅黑',
                        fontSize: 12,
                    }
                },
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        inside: false,
                        textStyle: {
                            color: "RGB(78,184,252)",
                            fontSize: 25,
                        },
                        show: false
                    },
                    data: optionData.yAxis
                }],
                xAxis: [{
                    show: false
                }],
                series: optionData.series
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.bottomgraph {
    width: 100%;
    height: 100%;
}
</style>
      