<template>
    <div class="indexCard">
        <div class="title">{{ data?.label }}</div>
        <div class="bottom">
            <div class="cardicon" :style="{ 'background-color': data?.bgColor }">
                <img :src="data?.icon" alt="icon">
            </div>
            <div class="index"><span class="value">{{ data?.value }}</span><span class="desc">次</span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "indexcard",
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    }
}
</script>

<style scoped lang="scss">
.indexCard {
    width: 100%;
    height: 100%;
    padding: 13px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);

    .title {
        height: 20px;
        opacity: 1;
        /** 文本1 */
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cardicon {
            width: 40px;
            height: 40px;
            opacity: 1;
            border-radius: 10px;

            img {
                width: 20px;
                width: 18px;
                margin-left:10px;
                margin-top: 11px;

            }
        }

        .index {
            display: flex;
            flex-direction: column;

            .value {
                opacity: 1;
                /** 文本1 */
                font-size: 24px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(51, 51, 51, 1);
            }

            .desc {
                opacity: 1;
                /** 文本1 */
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                text-align: right;
                color: rgba(153, 153, 153, 1);
            }
        }
    }
}
</style>