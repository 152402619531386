<template>
    <div class="pagecontainer">
        <div class="container_engineering">
            <!-- 顶部 -->
            <div class="header">
                <div class="title">国家工程技术研究中心指标统计</div>
                <div class="lefttimers">
                    年份切换 : <select name="year" id="year" v-model="year">
                        <option v-for='(item, index) in years' :key="index" :value="item">{{ item }}</option>
                    </select>
                </div>
            </div>
            <!-- /顶部 -->
            <!-- 内容 -->
            <div class="content">
                <!-- 第一行 -->
                <div class="content_one">
                    <!-- 左侧 -->
                    <div class="content_one_left">
                        <div class="content_one_left_1 chart_box">
                            <Box :title="projectCenterName">
                                <template v-slot:content>
                                    <LeftTop :projectCenterName="projectCenterName" :year="year" />
                                </template>
                            </Box>
                        </div>
                        <div class="content_one_left_2 chart_box">
                            <Box :title="year + '年度指标总得分'">
                                <template v-slot:content>
                                    <LeftCenter :topTotalScore="topTotalScore" />
                                </template>
                            </Box>
                        </div>
                    </div>
                    <!-- / 左侧 -->
                    <!-- 中间 -->
                    <div class="content_one_center">
                        <div class="chart_box content_one_center_map">
                            <graphbox :projectCenters="projectCenters" :year="year"
                                @projectCenterNameChanged="(val) => projectCenterName = val" />
                        </div>
                    </div>
                    <!-- / 中间 -->
                    <!-- 右侧 -->
                    <div class="content_one_right">
                        <div class="content_one_right_2 chart_box">
                            <Box title="年度其他合作情况">
                                <template v-slot:content>
                                    <select v-model="ProjectIndex" style="position: absolute;
    width: 136px;
    right: 112px;
    top: 7px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                        <option style="color: #000;" v-for="(item, index) in ProjectList"
                                            :value="item?.value" :label="item?.label">
                                        </option>
                                    </select>
                                    <RightTop :projectList="projectList" :ProjectIndex="ProjectIndex" />
                                </template>
                            </Box>
                        </div>
                    </div>
                    <!-- / 右侧 -->
                </div>
                <!-- /第一行 -->
                <!-- 第二行 -->
                <div class="content_two">
                    <!-- <div class="content_tow_1 chart_box">
                        <Box title="年度纵向承担重大项目情况 ">
                            <template v-slot:content>
                                <LeftBottom :projectCenterName="projectCenterName" :year="year" />
                            </template>
                        </Box>
                    </div> -->
                    <div class="content_tow_2 chart_box" style="position: relative;">
                        <Box title="软硬件投入情况">
                            <template v-slot:content>
                                <select v-model="innovationIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                    <option style="color: #000;" v-for="(item, index) in innovationIndexList"
                                        :value="item?.value" :label="item?.label">
                                    </option>
                                </select>
                                <CenterBottom :wareList="wareList" :innovationIndex="innovationIndex" />
                            </template>
                        </Box>
                    </div>
                    <div class="content_tow_3 chart_box" style="position: relative;">
                        <Box title="年度创新成果产出情况">
                            <template v-slot:content>
                                <select v-model="OutputIndex" style="position: absolute;
    width: 201px;
    right: 25px;
    top: 18px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                    <option style="color: #000;" v-for="(item, index) in OutputList"
                                        :value="item?.value" :label="item?.label">
                                    </option>
                                </select>
                                <RightBottom :outputList="outputList" :OutputIndex="OutputIndex" />
                            </template>
                        </Box>
                    </div>
                    <div class="content_tow_4 chart_box" style="position: relative;">
                        <Box title="年度人才团队建设情况  ">
                            <template v-slot:content>
                                <select v-model="TeamIndex" style="position: absolute;
    width: 136px;
    right: 112px;
    top: 7px;
    background: transparent;
    color: #ffffff;
    border: none;">
                                    <option style="color: #000;" v-for="(item, index) in TeamList" :value="item?.value"
                                        :label="item?.label">
                                    </option>
                                </select>
                                <RightCenter :teamList="teamList" :TeamIndex="TeamIndex" />
                            </template>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Box from "./components/Box";
import graphbox from './components/graphbox'
import LeftTop from './components/LeftTop'
import LeftCenter from './components/LeftCenter.vue'
import LeftBottom from './components/LeftBottom.vue'
import RightTop from './components/RightTop.vue'
import RightCenter from './components/RightCenter.vue'
import RightBottom from './components/RightBottom.vue'
import CenterBottom from './components/CenterBottom.vue'
export default {
    name: "engineering",
    layout: 'unauthenticated',
    components: {
        Box, graphbox, LeftTop, LeftCenter, LeftBottom, RightTop, RightCenter, RightBottom, CenterBottom
    },
    data() {
        return {
            allprojectCenters: [],
            projectCenters: [],
            projectCenterName: '',
            year: 2024,
            years: [],
            topTotalScore: [],
            ProjectIndex: '',
            ProjectList: [
                { label: '技术转移项目', value: 'techTransferCnt' },
                { label: '合同金额', value: 'contractAmt' },
                { label: '设立横向合作课题项目数', value: 'cooperProjectCnt' },
                { label: '横向合作课题资金', value: 'cooperProjectFunding' },
                { label: '合作研发项目数', value: 'cooperRndProjectCnt' },
                { label: '委托合作项目数', value: 'commitCooperProjectsCnt' },
                { label: '牵头举办国际(内)学术交流次', value: 'academicExchangeEventsCnt' },
                { label: '与高校院所企业签订产学研合作协议', value: 'academicResearchCooperAgreementsCnt' },
            ],
            projectList: [],
            TeamList: [
                { "label": "人员总数", "value": "totalPersonnelCnt" },
                { "label": "科研人员数", "value": "researchPersonnelCnt" },
                { "label": "管理人员数", "value": "managePersonnelCnt" },
                { "label": "高级职称数", "value": "seniorProfessionalTitleCnt" },
                { "label": "博士数", "value": "doctorateCnt" },
                { "label": "硕士数", "value": "masterCnt" },
                { "label": "院士数", "value": "academicianCnt" },
                { "label": "国家重大人才工程计划数", "value": "nationalMajorTalentProjectCnt" },
                { "label": "国家高层次人才特殊支持计划数", "value": "nationalHighLevelTalentsPlanCnt" },
                { "label": "国家杰青数", "value": "nationalYoungExpertsCnt" },
                { "label": "长江学者数", "value": "yangtzeScholarsCnt" },
                { "label": "江苏省六大人才高峰数", "value": "sixTalentsPeakCnt" },
                { "label": "省双创人才数", "value": "provDoubleTalentsCnt" },
                { "label": "省333工程第一层次培养对象", "value": "prov333FirstLevelCnt" },
                { "label": "省333工程第二层次培养对象", "value": "prov333SecondLevelCnt" },
                { "label": "省333工程第三层次培养对象", "value": "prov333ThirdLevelCnt" },
                { "label": "江苏省创新团队计划数", "value": "provInnovationTeamPlanCnt" },
                { "label": "当年新增人员数", "value": "newlyAddedPersonnelCnt" }
            ],
            teamList: [],
            TeamIndex: '',
            OutputList: [
                { label: '新产品', value: 'newProductCnt' },
                { label: '新技术', value: 'newTechCnt' },
                { label: '新工艺', value: 'newProcessCnt' },
                { label: '获省部级及以上科技奖励数', value: 'aboveProvTechAwardCnt' },
                { label: '发明专利申请数', value: 'inventionPatentApplicationsCnt' },
                { label: '专利申请总数', value: 'totalPatentApplicationsCnt' },
                { label: '发明专利授权数', value: 'inventionPatentGrantsCnt' },
                { label: '专利授权总数', value: 'totalPatentGrantsCnt' },
                { label: '医药新药证书数', value: 'newDrugCertForMedicCnt' },
                { label: '农药新药证书数', value: 'newDrugCertForAgriCnt' },
                { label: '兽药新药证书数', value: 'newDrugCertForVeteriCnt' },
                { label: '动植物新品种审定数', value: 'newVarietiesAnimalAndPlantCnt' },
                { label: '软件著作权数', value: 'softwareCopyrightsCnt' },
                { label: '集成电路设计版权数', value: 'integratedCircuitDesignCnt' },
                { label: '国际标准数', value: 'internationalStandardCnt' },
                { label: '国家标准数', value: 'nationalStandardCnt' },
                { label: '地方标准数', value: 'localStandardCnt' },
                { label: '行业标准数', value: 'industryStandardCnt' }
            ],
            OutputIndex: '',
            outputList: [],
            wareList: [],
            innovationIndex: '',
            innovationIndexList: [
                { label: '研发场所面积', value: 'rndFacilityArea' },
                { label: '仪器设备累计投入（万元）', value: 'accumulatedEquipmentInvestment' },
                { label: '50万以上仪器设备投入(万元)', value: 'above50EquipmentInvestment' },
                { label: '累计拥有仪器设备(台/套)', value: 'totalEquipmentOwnership' },
                { label: '50万以上仪器设备(台/套)', value: 'above50EquipmentCnt' },
                { label: '年度研发经费投入总额(万元)', value: 'annualRndCosts' },
            ]
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        year: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data = new FormData()
                    data.append('current', 1)
                    data.append('pageSize', 20)
                    data.append('sortField', 'totalScore')
                    data.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterIndicatorsList', data).then(res => {
                        this.topTotalScore = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })

                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', this.innovationIndex)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterHardwareInvestmentList', data1).then(res => {
                        this.wareList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })

                    const data2 = new FormData()
                    data2.append('current', 1)
                    data2.append('pageSize', 10000)
                    data2.append('sortField', this.ProjectIndex)
                    data2.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/NationalProjectTechCenterOtherCollaborationsList', data2).then(res => {
                        this.projectList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })

                    const data4 = new FormData()
                    data4.append('current', 1)
                    data4.append('pageSize', 1000)
                    data4.append('sortField', this.TeamIndex)
                    data4.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterTalentTeamIndicatorsList', data4).then(res => {
                        this.teamList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })



                    const data3 = new FormData()
                    data3.append('current', 1)
                    data3.append('pageSize', 1000)
                    data3.append('sortField', this.OutputIndex)
                    data3.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterAnnualNewInnovationOutputList', data3).then(res => {
                        this.outputList = res.data.data.records.filter(item => item.statisticalYear === Number(val))
                    })
                })
            }
        },
        TeamIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterTalentTeamIndicatorsList', data1).then(res => {
                        this.teamList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        ProjectIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 10000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'desc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalEngineeringCenterAnnualOperationMetricsList', data1).then(res => {
                        this.projectList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        OutputIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterAnnualNewInnovationOutputList', data1).then(res => {
                        this.outputList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
        innovationIndex: {
            handler: function (val) {
                this.$nextTick(() => {
                    const data1 = new FormData()
                    data1.append('current', 1)
                    data1.append('pageSize', 1000)
                    data1.append('sortField', val)
                    data1.append('sortOrder', 'asc')
                    this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterHardwareInvestmentList', data1).then(res => {
                        this.wareList = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
                    })
                })
            }
        },
    },
    methods: {
        init() {
            this.innovationIndex = this.innovationIndexList[0].value
            this.ProjectIndex = this.ProjectList[3].value
            this.TeamIndex = this.TeamList[11].value
            this.OutputIndex = this.OutputList[0].value
            this.$axios.get('/indicator/nationProjectTechCenterIndicator/list/nationalProjectTechCenterInfoList').then(res => {
                this.projectCenters = res.data.data
                const filtersLabs = res.data.data.filter(item => item.statisticalYear === Number(this.year))
                if (filtersLabs[0]?.projectCenterName) {
                    this.projectCenterName = filtersLabs[0].projectCenterName
                }
                this.years = this.getUniqueYears(this.projectCenters)
            })

            const data = new FormData()
            data.append('current', 1)
            data.append('pageSize', 20)
            data.append('sortField', 'totalScore')
            data.append('sortOrder', 'desc')
            this.$axios.post('/indicator/nationProjectTechCenterIndicator/list/pageNationalProjectTechCenterIndicatorsList', data).then(res => {
                this.topTotalScore = res.data.data.records.filter(item => item.statisticalYear === Number(this.year))
            })
        },
        getUniqueYears(data) {
            const uniqueYears = [];
            data.forEach(entry => {
                const year = entry.statisticalYear;
                if (!uniqueYears.includes(year)) {
                    uniqueYears.push(year);
                }
            });
            return uniqueYears;
        },
    }
}
</script>

<style scoped lang="scss">
.pagecontainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url(../../assets/images/img/layout/container_bg.png);
    background-size: 100% 100%;
    overflow: hidden;

    .container_engineering {
        div {
            box-sizing: border-box;
        }

        .header {
            height: 200px;
            background: url(../../assets/images/img/layout/header.png);
            background-size: 100% 200px;

            .title {
                width: 400px;
                margin: 0 auto;
                text-align: center;
                color: #fff;
                font-size: 28px;
            }

            .lefttimers {
                position: absolute;
                left: 32px;
                top: 10px;
                font-size: 16px;
                width: 200px;
                display: flex;
                align-items: center;
                color: #ffffff;
                cursor: pointer;

                select {
                    width: 56%;
                    background: transparent;
                    border: none;
                    color: #ffffff;
                    text-align: right;

                    option {
                        text-align: center;
                        color: #000
                    }
                }
            }
        }

        .content {
            position: absolute;
            top: 60px;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: column;

            .chart_box {
                background: url(../../assets/images/img/layout/chart_box_bg.png);
                background-size: 100% 100%;
                margin: 0 0.5em 0.5em 0.5em;
                padding: 0.5em;
            }

            .content_one {
                flex: 2;
                display: flex;
                flex-direction: row;

                .content_one_left,
                .content_one_right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    &>div {
                        flex: 1;
                    }
                }

                .content_one_center {
                    flex: 2;
                    display: flex;
                    flex-direction: column;

                    &>div {
                        background: url(../../assets/images/img/layout/chart_center_box_bg.png);
                        background-size: 100% 100%;
                        flex: 1;
                        margin: 0 0 0.5em 0;
                    }

                    .content_one_center_map {
                        padding: 1em 0.5em;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                }
            }

            .content_two {
                flex: 1;
                display: flex;
                flex-direction: row;
                overflow: hidden;

                &>div {
                    flex: 1;
                    min-width: 0;
                }
            }
        }
    }

}
</style>