<template>
    <div class="ringgraph" ref="ringgraph">
    </div>
</template>
      
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
    name: 'topring',
    props: {
        performanceEvaluation: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        performanceEvaluation: {
            immediate: true,
            handler: function (val) {
                this.$nextTick(() => {
                    if (val !== null) {
                        this.initChart(val)
                    }
                })
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        initChart(performanceEvaluation) {
            this.myChart = echarts.init(this.$refs['ringgraph']);
            const scale = 1;
            const echartData = [{
                value: Number(performanceEvaluation?.teamBuildingScore),
                name: '团队建设得分'
            }, {
                value: Number(performanceEvaluation?.businessScore),
                name: '经营情况得分'
            }, {
                value: Number(performanceEvaluation?.rndScore),
                name: '研发情况得分'
            }, {
                value: Number(performanceEvaluation?.researchOutputScore),
                name: '科研产出得分'
            }, {
                value: Number(performanceEvaluation?.incubationOutputScore),
                name: '孵化产出得分'
            }, {
                value: Number(performanceEvaluation?.fundingScore),
                name: '融资情况得分'
            }, {
                value: Number(performanceEvaluation?.extraFeatureScore),
                name: '额外特色得分'
            }]
            const rich = {
                yellow: {
                    color: "#ffc72b",
                    fontSize: 25 * scale,
                    padding: [5, 4],
                    align: 'center'
                },
                total: {
                    color: "#ffc72b",
                    fontSize: 35 * scale,
                    align: 'center'
                },
                white: {
                    color: "#fff",
                    align: 'center',
                    fontSize: 12 * scale,
                    padding: [21, 0]
                },
                blue: {
                    color: '#49dff0',
                    fontSize: 14 * scale,
                    align: 'center'
                },
                hr: {
                    borderColor: '#0b5263',
                    width: '100%',
                    borderWidth: 1,
                    height: 0,
                }
            }
            const option = {
                legend: {
                    selectedMode: false,
                    formatter: function (name) {
                        var total = 0;
                        var averagePercent;
                        echartData.forEach(function (value, index, array) {
                            total += value.value;
                        });
                        return '{total|' + Number(total.toFixed(1)) + '}';
                    },
                    data: [echartData[0].name],
                    left: 'center',
                    top: 'center',
                    icon: 'none',
                    align: 'center',
                    textStyle: {
                        color: "#fff",
                        fontSize: 12 * scale,
                        rich: rich
                    },
                },
                series: [{
                    name: '总考生数量',
                    type: 'pie',
                    radius: ['36%', '48%'],
                    hoverAnimation: false,
                    color: ['#c487ee', '#deb140', '#49dff0', '#034079', '#6f81da', '#00ffb4'],
                    label: {
                        normal: {
                            formatter: function (params, ticket, callback) {
                                var total = 0; //考生总数量
                                var percent = 0; //考生占比
                                echartData.forEach(function (value, index, array) {
                                    total += value.value;
                                });
                                percent = ((params.value / total) * 100).toFixed(1);
                                return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '}\n{blue|' + percent + '%}';
                            },
                            rich: rich
                        },
                    },
                    labelLine: {
                        normal: {
                            length: 35 * scale,
                            length2: 0,
                            lineStyle: {
                                color: '#0b5263'
                            }
                        }
                    },
                    data: echartData
                }]
            };
            this.myChart.setOption(option);
        }
    }
}
</script>
      
<style lang="scss" scoped >
.ringgraph {
    width: 100%;
    height: 100%;
}
</style>
      